var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"title":_vm.modalTitle,"body-class":_vm.getModalClass,"hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.performSave)}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-2","label":"Name:","label-for":"input-2"}},[_c('b-form-input',{attrs:{"id":"input-2","type":"text","placeholder":"Enter name","required":""},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-button',{staticClass:"float-right",attrs:{"id":"role-help-popover","variant":"light","size":"sm","tabindex":"0"}},[_c('i',{staticClass:"fas fa-question"})]),_c('b-popover',{attrs:{"target":"role-help-popover","title":"Role","triggers":"hover"}},[_c('strong',[_vm._v("Users")]),_vm._v(" can only contribute to notes which they have created or to which their profiles have been assigned."),_c('br'),_c('strong',[_vm._v("Administrators")]),_vm._v(" can access all notes and have permission to delete them or mark them as resolved. We recommend to assign at least one administrator."),_c('br'),_c('br'),_vm._v(" You can change any user's role at any time. ")]),_c('b-form-group',{class:classes,attrs:{"id":"input-group-1","label":"Role:","label-for":"input-role"}},[_c('multiselect',{attrs:{"value":_vm.form.role,"id":"input-role","label":"text","track-by":"value","options":_vm.roleOptions,"size":"sm","multiple":false,"searchable":false},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-4","label":"Email address:","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","type":"email","placeholder":"Enter email","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"","name":"categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{ref:"iconChooser",class:classes,attrs:{"id":"input-group-3","label":"Categories:","label-for":"input-3"}},[_c('multiselect',{attrs:{"searchable":true,"close-on-select":false,"clear-on-select":false,"label":"name","track-by":"id","taggable":true,"multiple":true,"options":_vm.categories,"open-direction":"bottom"},on:{"tag":_vm.addTag,"open":_vm.autoScrollMe,"close":_vm.multiselectClosed},scopedSlots:_vm._u([{key:"tag",fn:function(props){return [_c('span',{staticClass:"multiselect__tag",style:({backgroundColor: props.option.color})},[_c('span',{staticClass:"tag__title"},[_vm._v(_vm._s(props.option.name))]),_vm._v(" "),_c('i',{staticClass:"multiselect__tag-icon",on:{"mousedown":function($event){$event.preventDefault();return props.remove(props.option)}}})])]}}],null,true),model:{value:(_vm.form.assigned_thread_categories),callback:function ($$v) {_vm.$set(_vm.form, "assigned_thread_categories", $$v)},expression:"form.assigned_thread_categories"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),(_vm.mode === 'create')?_c('div',{staticClass:"create-user-message"},[_c('small',[_c('em',[_vm._v("You are about to create a new user. The credentials will be sent by email to the new user.")])])]):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-outline-dark",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return handleSubmit(_vm.performSave)}}},[(_vm.mode === 'create')?_c('span',[_vm._v("Create")]):_c('span',[_vm._v("Save")])])]},proxy:true}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }