export default { options: [
  {"name": "500px", "value": "fab,500px"}, 
  {"name": "Accessible Icon", "value": "fab,accessible-icon"}, 
  {"name": "Accusoft", "value": "fab,accusoft"}, 
  {"name": "Acquisitions Incorporated", "value": "fab,acquisitions-incorporated"}, 
  {"name": "Ad", "value": "fas,ad"}, 
  {"name": "Address Book (solid)", "value": "fas,address-book"}, 
  {"name": "Address Book (regular)", "value": "far,address-book"}, 
  {"name": "Address Card (solid)", "value": "fas,address-card"}, 
  {"name": "Address Card (regular)", "value": "far,address-card"}, 
  {"name": "adjust", "value": "fas,adjust"}, 
  {"name": "App.net", "value": "fab,adn"},
  {"name": "Adobe", "value": "fab,adobe"}, 
  {"name": "Adversal", "value": "fab,adversal"}, 
  {"name": "affiliatetheme", "value": "fab,affiliatetheme"}, 
  {"name": "Air Freshener", "value": "fas,air-freshener"}, 
  {"name": "Algolia", "value": "fab,algolia"}, 
  {"name": "align-center", "value": "fas,align-center"}, 
  {"name": "align-justify", "value": "fas,align-justify"}, 
  {"name": "align-left", "value": "fas,align-left"}, 
  {"name": "align-right", "value": "fas,align-right"}, 
  {"name": "Alipay", "value": "fab,alipay"}, 
  {"name": "Allergies", "value": "fas,allergies"}, 
  {"name": "Amazon", "value": "fab,amazon"}, 
  {"name": "Amazon Pay", "value": "fab,amazon-pay"}, 
  {"name": "ambulance", "value": "fas,ambulance"}, 
  {"name": "American Sign Language Interpreting", "value": "fas,american-sign-language-interpreting"}, 
  {"name": "Amilia", "value": "fab,amilia"}, 
  {"name": "Anchor", "value": "fas,anchor"}, 
  {"name": "Android", "value": "fab,android"}, 
  {"name": "AngelList", "value": "fab,angellist"}, 
  {"name": "Angle Double Down", "value": "fas,angle-double-down"}, 
  {"name": "Angle Double Left", "value": "fas,angle-double-left"}, 
  {"name": "Angle Double Right", "value": "fas,angle-double-right"}, 
  {"name": "Angle Double Up", "value": "fas,angle-double-up"}, 
  {"name": "angle-down", "value": "fas,angle-down"}, 
  {"name": "angle-left", "value": "fas,angle-left"}, 
  {"name": "angle-right", "value": "fas,angle-right"}, 
  {"name": "angle-up", "value": "fas,angle-up"}, 
  {"name": "Angry Face (solid)", "value": "fas,angry"}, 
  {"name": "Angry Face (regular)", "value": "far,angry"}, 
  {"name": "Angry Creative", "value": "fab,angrycreative"}, 
  {"name": "Angular", "value": "fab,angular"}, 
  {"name": "Ankh", "value": "fas,ankh"}, 
  {"name": "App Store", "value": "fab,app-store"}, 
  {"name": "iOS App Store", "value": "fab,app-store-ios"}, 
  {"name": "Apper Systems AB", "value": "fab,apper"}, 
  {"name": "Apple", "value": "fab,apple"}, 
  {"name": "Fruit Apple", "value": "fas,apple-alt"}, 
  {"name": "Apple Pay", "value": "fab,apple-pay"}, 
  {"name": "Archive", "value": "fas,archive"}, 
  {"name": "Archway", "value": "fas,archway"}, 
  {"name": "Alternate Arrow Circle Down (solid)", "value": "fas,arrow-alt-circle-down"}, 
  {"name": "Alternate Arrow Circle Down (regular)", "value": "far,arrow-alt-circle-down"}, 
  {"name": "Alternate Arrow Circle Left (solid)", "value": "fas,arrow-alt-circle-left"}, 
  {"name": "Alternate Arrow Circle Left (regular)", "value": "far,arrow-alt-circle-left"}, 
  {"name": "Alternate Arrow Circle Right (solid)", "value": "fas,arrow-alt-circle-right"}, 
  {"name": "Alternate Arrow Circle Right (regular)", "value": "far,arrow-alt-circle-right"}, 
  {"name": "Alternate Arrow Circle Up (solid)", "value": "fas,arrow-alt-circle-up"}, 
  {"name": "Alternate Arrow Circle Up (regular)", "value": "far,arrow-alt-circle-up"}, 
  {"name": "Arrow Circle Down", "value": "fas,arrow-circle-down"}, 
  {"name": "Arrow Circle Left", "value": "fas,arrow-circle-left"}, 
  {"name": "Arrow Circle Right", "value": "fas,arrow-circle-right"}, 
  {"name": "Arrow Circle Up", "value": "fas,arrow-circle-up"}, 
  {"name": "arrow-down", "value": "fas,arrow-down"}, 
  {"name": "arrow-left", "value": "fas,arrow-left"}, 
  {"name": "arrow-right", "value": "fas,arrow-right"}, 
  {"name": "arrow-up", "value": "fas,arrow-up"}, 
  {"name": "Alternate Arrows", "value": "fas,arrows-alt"}, 
  {"name": "Alternate Arrows Horizontal", "value": "fas,arrows-alt-h"}, 
  {"name": "Alternate Arrows Vertical", "value": "fas,arrows-alt-v"}, 
  {"name": "Artstation", "value": "fab,artstation"}, 
  {"name": "Assistive Listening Systems", "value": "fas,assistive-listening-systems"}, 
  {"name": "asterisk", "value": "fas,asterisk"}, 
  {"name": "Asymmetrik, Ltd.", "value": "fab,asymmetrik"}, 
  {"name": "At", "value": "fas,at"}, 
  {"name": "Atlas", "value": "fas,atlas"}, 
  {"name": "Atlassian", "value": "fab,atlassian"}, 
  {"name": "Atom", "value": "fas,atom"}, 
  {"name": "Audible", "value": "fab,audible"}, 
  {"name": "Audio Description", "value": "fas,audio-description"}, 
  {"name": "Autoprefixer", "value": "fab,autoprefixer"}, 
  {"name": "avianex", "value": "fab,avianex"}, 
  {"name": "Aviato", "value": "fab,aviato"}, 
  {"name": "Award", "value": "fas,award"}, 
  {"name": "Amazon Web Services (AWS)", "value": "fab,aws"}, 
  {"name": "Baby", "value": "fas,baby"}, 
  {"name": "Baby Carriage", "value": "fas,baby-carriage"}, 
  {"name": "Backspace", "value": "fas,backspace"}, 
  {"name": "backward", "value": "fas,backward"}, 
  {"name": "Balance Scale", "value": "fas,balance-scale"}, 
  {"name": "ban", "value": "fas,ban"}, 
  {"name": "Band-Aid", "value": "fas,band-aid"}, 
  {"name": "Bandcamp", "value": "fab,bandcamp"}, 
  {"name": "barcode", "value": "fas,barcode"}, 
  {"name": "Bars", "value": "fas,bars"}, 
  {"name": "Baseball Ball", "value": "fas,baseball-ball"}, 
  {"name": "Basketball Ball", "value": "fas,basketball-ball"}, 
  {"name": "Bath", "value": "fas,bath"}, 
  {"name": "Battery Empty", "value": "fas,battery-empty"}, 
  {"name": "Battery Full", "value": "fas,battery-full"}, 
  {"name": "Battery 1/2 Full", "value": "fas,battery-half"}, 
  {"name": "Battery 1/4 Full", "value": "fas,battery-quarter"}, 
  {"name": "Battery 3/4 Full", "value": "fas,battery-three-quarters"}, 
  {"name": "Bed", "value": "fas,bed"}, 
  {"name": "beer", "value": "fas,beer"}, 
  {"name": "Behance", "value": "fab,behance"}, 
  {"name": "Behance Square", "value": "fab,behance-square"}, 
  {"name": "bell (solid)", "value": "fas,bell"}, 
  {"name": "bell (regular)", "value": "far,bell"}, 
  {"name": "Bell Slash (solid)", "value": "fas,bell-slash"}, 
  {"name": "Bell Slash (regular)", "value": "far,bell-slash"}, 
  {"name": "Bezier Curve", "value": "fas,bezier-curve"}, 
  {"name": "Bible", "value": "fas,bible"}, 
  {"name": "Bicycle", "value": "fas,bicycle"}, 
  {"name": "BIMobject", "value": "fab,bimobject"}, 
  {"name": "Binoculars", "value": "fas,binoculars"}, 
  {"name": "Biohazard", "value": "fas,biohazard"}, 
  {"name": "Birthday Cake", "value": "fas,birthday-cake"}, 
  {"name": "Bitbucket", "value": "fab,bitbucket"}, 
  {"name": "Bitcoin", "value": "fab,bitcoin"}, 
  {"name": "Bity", "value": "fab,bity"}, 
  {"name": "Font Awesome Black Tie", "value": "fab,black-tie"}, 
  {"name": "BlackBerry", "value": "fab,blackberry"}, 
  {"name": "Blender", "value": "fas,blender"}, 
  {"name": "Blender Phone", "value": "fas,blender-phone"}, 
  {"name": "Blind", "value": "fas,blind"}, 
  {"name": "Blog", "value": "fas,blog"}, 
  {"name": "Blogger", "value": "fab,blogger"}, 
  {"name": "Blogger B", "value": "fab,blogger-b"}, 
  {"name": "Bluetooth", "value": "fab,bluetooth"}, 
  {"name": "Bluetooth", "value": "fab,bluetooth-b"}, 
  {"name": "bold", "value": "fas,bold"}, 
  {"name": "Lightning Bolt", "value": "fas,bolt"}, 
  {"name": "Bomb", "value": "fas,bomb"}, 
  {"name": "Bone", "value": "fas,bone"}, 
  {"name": "Bong", "value": "fas,bong"}, 
  {"name": "book", "value": "fas,book"}, 
  {"name": "Book of the Dead", "value": "fas,book-dead"}, 
  {"name": "Book Open", "value": "fas,book-open"}, 
  {"name": "Book Reader", "value": "fas,book-reader"}, 
  {"name": "bookmark (solid)", "value": "fas,bookmark"}, 
  {"name": "bookmark (regular)", "value": "far,bookmark"}, 
  {"name": "Bowling Ball", "value": "fas,bowling-ball"}, 
  {"name": "Box", "value": "fas,box"}, 
  {"name": "Box Open", "value": "fas,box-open"}, 
  {"name": "Boxes", "value": "fas,boxes"}, 
  {"name": "Braille", "value": "fas,braille"}, 
  {"name": "Brain", "value": "fas,brain"}, 
  {"name": "Briefcase", "value": "fas,briefcase"}, 
  {"name": "Medical Briefcase", "value": "fas,briefcase-medical"}, 
  {"name": "Broadcast Tower", "value": "fas,broadcast-tower"}, 
  {"name": "Broom", "value": "fas,broom"}, 
  {"name": "Brush", "value": "fas,brush"}, 
  {"name": "BTC", "value": "fab,btc"}, 
  {"name": "Bug", "value": "fas,bug"}, 
  {"name": "Building (solid)", "value": "fas,building"}, 
  {"name": "Building (regular)", "value": "far,building"}, 
  {"name": "bullhorn", "value": "fas,bullhorn"}, 
  {"name": "Bullseye", "value": "fas,bullseye"}, 
  {"name": "Burn", "value": "fas,burn"}, 
  {"name": "B\\u00fcrom\\u00f6bel-Experte GmbH & Co. KG.", "value": "fab,buromobelexperte"}, 
  {"name": "Bus", "value": "fas,bus"}, 
  {"name": "Bus Alt", "value": "fas,bus-alt"}, 
  {"name": "Business Time", "value": "fas,business-time"}, 
  {"name": "BuySellAds", "value": "fab,buysellads"}, 
  {"name": "Calculator", "value": "fas,calculator"}, 
  {"name": "Calendar (solid)", "value": "fas,calendar"}, 
  {"name": "Calendar (regular)", "value": "far,calendar"}, 
  {"name": "Alternate Calendar (solid)", "value": "fas,calendar-alt"}, 
  {"name": "Alternate Calendar (regular)", "value": "far,calendar-alt"}, 
  {"name": "Calendar Check (solid)", "value": "fas,calendar-check"}, 
  {"name": "Calendar Check (regular)", "value": "far,calendar-check"}, 
  {"name": "Calendar with Day Focus", "value": "fas,calendar-day"}, 
  {"name": "Calendar Minus (solid)", "value": "fas,calendar-minus"}, 
  {"name": "Calendar Minus (regular)", "value": "far,calendar-minus"}, 
  {"name": "Calendar Plus (solid)", "value": "fas,calendar-plus"}, 
  {"name": "Calendar Plus (regular)", "value": "far,calendar-plus"}, 
  {"name": "Calendar Times (solid)", "value": "fas,calendar-times"}, 
  {"name": "Calendar Times (regular)", "value": "far,calendar-times"}, 
  {"name": "Calendar with Week Focus", "value": "fas,calendar-week"}, 
  {"name": "camera", "value": "fas,camera"}, 
  {"name": "Retro Camera", "value": "fas,camera-retro"}, 
  {"name": "Campground", "value": "fas,campground"}, 
  {"name": "Canadian Maple Leaf", "value": "fab,canadian-maple-leaf"}, 
  {"name": "Candy Cane", "value": "fas,candy-cane"}, 
  {"name": "Cannabis", "value": "fas,cannabis"}, 
  {"name": "Capsules", "value": "fas,capsules"}, 
  {"name": "Car", "value": "fas,car"}, 
  {"name": "Alternate Car", "value": "fas,car-alt"}, 
  {"name": "Car Battery", "value": "fas,car-battery"}, 
  {"name": "Car Crash", "value": "fas,car-crash"}, 
  {"name": "Car Side", "value": "fas,car-side"}, 
  {"name": "Caret Down", "value": "fas,caret-down"}, 
  {"name": "Caret Left", "value": "fas,caret-left"}, 
  {"name": "Caret Right", "value": "fas,caret-right"}, 
  {"name": "Caret Square Down (solid)", "value": "fas,caret-square-down"}, 
  {"name": "Caret Square Down (regular)", "value": "far,caret-square-down"}, 
  {"name": "Caret Square Left (solid)", "value": "fas,caret-square-left"}, 
  {"name": "Caret Square Left (regular)", "value": "far,caret-square-left"}, 
  {"name": "Caret Square Right (solid)", "value": "fas,caret-square-right"}, 
  {"name": "Caret Square Right (regular)", "value": "far,caret-square-right"}, 
  {"name": "Caret Square Up (solid)", "value": "fas,caret-square-up"}, 
  {"name": "Caret Square Up (regular)", "value": "far,caret-square-up"}, 
  {"name": "Caret Up", "value": "fas,caret-up"}, 
  {"name": "Carrot", "value": "fas,carrot"}, 
  {"name": "Shopping Cart Arrow Down", "value": "fas,cart-arrow-down"}, 
  {"name": "Add to Shopping Cart", "value": "fas,cart-plus"}, 
  {"name": "Cash Register", "value": "fas,cash-register"}, 
  {"name": "Cat", "value": "fas,cat"}, 
  {"name": "Amazon Pay Credit Card", "value": "fab,cc-amazon-pay"}, 
  {"name": "American Express Credit Card", "value": "fab,cc-amex"}, 
  {"name": "Apple Pay Credit Card", "value": "fab,cc-apple-pay"}, 
  {"name": "Diners Club Credit Card", "value": "fab,cc-diners-club"}, 
  {"name": "Discover Credit Card", "value": "fab,cc-discover"}, 
  {"name": "JCB Credit Card", "value": "fab,cc-jcb"}, 
  {"name": "MasterCard Credit Card", "value": "fab,cc-mastercard"}, 
  {"name": "Paypal Credit Card", "value": "fab,cc-paypal"}, 
  {"name": "Stripe Credit Card", "value": "fab,cc-stripe"}, 
  {"name": "Visa Credit Card", "value": "fab,cc-visa"}, 
  {"name": "Centercode", "value": "fab,centercode"}, 
  {"name": "Centos", "value": "fab,centos"}, 
  {"name": "certificate", "value": "fas,certificate"}, 
  {"name": "Chair", "value": "fas,chair"}, 
  {"name": "Chalkboard", "value": "fas,chalkboard"}, 
  {"name": "Chalkboard Teacher", "value": "fas,chalkboard-teacher"}, 
  {"name": "Charging Station", "value": "fas,charging-station"}, 
  {"name": "Area Chart", "value": "fas,chart-area"}, 
  {"name": "Bar Chart (solid)", "value": "fas,chart-bar"}, 
  {"name": "Bar Chart (regular)", "value": "far,chart-bar"}, 
  {"name": "Line Chart", "value": "fas,chart-line"}, 
  {"name": "Pie Chart", "value": "fas,chart-pie"}, 
  {"name": "Check", "value": "fas,check"}, 
  {"name": "Check Circle (solid)", "value": "fas,check-circle"}, 
  {"name": "Check Circle (regular)", "value": "far,check-circle"}, 
  {"name": "Check Double", "value": "fas,check-double"}, 
  {"name": "Check Square (solid)", "value": "fas,check-square"}, 
  {"name": "Check Square (regular)", "value": "far,check-square"}, 
  {"name": "Chess", "value": "fas,chess"}, 
  {"name": "Chess Bishop", "value": "fas,chess-bishop"}, 
  {"name": "Chess Board", "value": "fas,chess-board"}, 
  {"name": "Chess King", "value": "fas,chess-king"}, 
  {"name": "Chess Knight", "value": "fas,chess-knight"}, 
  {"name": "Chess Pawn", "value": "fas,chess-pawn"}, 
  {"name": "Chess Queen", "value": "fas,chess-queen"}, 
  {"name": "Chess Rook", "value": "fas,chess-rook"}, 
  {"name": "Chevron Circle Down", "value": "fas,chevron-circle-down"}, 
  {"name": "Chevron Circle Left", "value": "fas,chevron-circle-left"}, 
  {"name": "Chevron Circle Right", "value": "fas,chevron-circle-right"}, 
  {"name": "Chevron Circle Up", "value": "fas,chevron-circle-up"}, 
  {"name": "chevron-down", "value": "fas,chevron-down"}, 
  {"name": "chevron-left", "value": "fas,chevron-left"}, 
  {"name": "chevron-right", "value": "fas,chevron-right"}, 
  {"name": "chevron-up", "value": "fas,chevron-up"}, 
  {"name": "Child", "value": "fas,child"}, 
  {"name": "Chrome", "value": "fab,chrome"}, 
  {"name": "Church", "value": "fas,church"}, 
  {"name": "Circle (solid)", "value": "fas,circle"}, 
  {"name": "Circle (regular)", "value": "far,circle"}, 
  {"name": "Circle Notched", "value": "fas,circle-notch"}, 
  {"name": "City", "value": "fas,city"}, 
  {"name": "Clipboard (solid)", "value": "fas,clipboard"}, 
  {"name": "Clipboard (regular)", "value": "far,clipboard"}, 
  {"name": "Clipboard with Check", "value": "fas,clipboard-check"}, 
  {"name": "Clipboard List", "value": "fas,clipboard-list"}, 
  {"name": "Clock (solid)", "value": "fas,clock"}, 
  {"name": "Clock (regular)", "value": "far,clock"}, 
  {"name": "Clone (solid)", "value": "fas,clone"}, 
  {"name": "Clone (regular)", "value": "far,clone"}, 
  {"name": "Closed Captioning (solid)", "value": "fas,closed-captioning"}, 
  {"name": "Closed Captioning (regular)", "value": "far,closed-captioning"}, 
  {"name": "Cloud", "value": "fas,cloud"}, 
  {"name": "Alternate Cloud Download", "value": "fas,cloud-download-alt"}, 
  {"name": "Cloud with (a chance of) Meatball", "value": "fas,cloud-meatball"}, 
  {"name": "Cloud with Moon", "value": "fas,cloud-moon"}, 
  {"name": "Cloud with Moon and Rain", "value": "fas,cloud-moon-rain"}, 
  {"name": "Cloud with Rain", "value": "fas,cloud-rain"}, 
  {"name": "Cloud with Heavy Showers", "value": "fas,cloud-showers-heavy"}, 
  {"name": "Cloud with Sun", "value": "fas,cloud-sun"}, 
  {"name": "Cloud with Sun and Rain", "value": "fas,cloud-sun-rain"}, 
  {"name": "Alternate Cloud Upload", "value": "fas,cloud-upload-alt"}, 
  {"name": "cloudscale.ch", "value": "fab,cloudscale"}, 
  {"name": "Cloudsmith", "value": "fab,cloudsmith"}, 
  {"name": "cloudversify", "value": "fab,cloudversify"}, 
  {"name": "Cocktail", "value": "fas,cocktail"}, 
  {"name": "Code", "value": "fas,code"}, 
  {"name": "Code Branch", "value": "fas,code-branch"}, 
  {"name": "Codepen", "value": "fab,codepen"}, 
  {"name": "Codie Pie", "value": "fab,codiepie"}, 
  {"name": "Coffee", "value": "fas,coffee"}, 
  {"name": "cog", "value": "fas,cog"}, 
  {"name": "cogs", "value": "fas,cogs"}, 
  {"name": "Coins", "value": "fas,coins"}, 
  {"name": "Columns", "value": "fas,columns"}, 
  {"name": "comment (solid)", "value": "fas,comment"}, 
  {"name": "comment (regular)", "value": "far,comment"}, 
  {"name": "Alternate Comment (solid)", "value": "fas,comment-alt"}, 
  {"name": "Alternate Comment (regular)", "value": "far,comment-alt"}, 
  {"name": "Comment Dollar", "value": "fas,comment-dollar"}, 
  {"name": "Comment Dots (solid)", "value": "fas,comment-dots"}, 
  {"name": "Comment Dots (regular)", "value": "far,comment-dots"}, 
  {"name": "Comment Slash", "value": "fas,comment-slash"}, 
  {"name": "comments (solid)", "value": "fas,comments"}, 
  {"name": "comments (regular)", "value": "far,comments"}, 
  {"name": "Comments Dollar", "value": "fas,comments-dollar"}, 
  {"name": "Compact Disc", "value": "fas,compact-disc"}, 
  {"name": "Compass (solid)", "value": "fas,compass"}, 
  {"name": "Compass (regular)", "value": "far,compass"}, 
  {"name": "Compress", "value": "fas,compress"}, 
  {"name": "Alternate Compress Arrows", "value": "fas,compress-arrows-alt"}, 
  {"name": "Concierge Bell", "value": "fas,concierge-bell"}, 
  {"name": "Confluence", "value": "fab,confluence"}, 
  {"name": "Connect Develop", "value": "fab,connectdevelop"}, 
  {"name": "Contao", "value": "fab,contao"}, 
  {"name": "Cookie", "value": "fas,cookie"}, 
  {"name": "Cookie Bite", "value": "fas,cookie-bite"}, 
  {"name": "Copy (solid)", "value": "fas,copy"}, 
  {"name": "Copy (regular)", "value": "far,copy"}, 
  {"name": "Copyright (solid)", "value": "fas,copyright"}, 
  {"name": "Copyright (regular)", "value": "far,copyright"}, 
  {"name": "Couch", "value": "fas,couch"}, 
  {"name": "cPanel", "value": "fab,cpanel"}, 
  {"name": "Creative Commons", "value": "fab,creative-commons"}, 
  {"name": "Creative Commons Attribution", "value": "fab,creative-commons-by"}, 
  {"name": "Creative Commons Noncommercial", "value": "fab,creative-commons-nc"}, 
  {"name": "Creative Commons Noncommercial (Euro Sign)", "value": "fab,creative-commons-nc-eu"}, 
  {"name": "Creative Commons Noncommercial (Yen Sign)", "value": "fab,creative-commons-nc-jp"}, 
  {"name": "Creative Commons No Derivative Works", "value": "fab,creative-commons-nd"}, 
  {"name": "Creative Commons Public Domain", "value": "fab,creative-commons-pd"}, 
  {"name": "Alternate Creative Commons Public Domain", "value": "fab,creative-commons-pd-alt"}, 
  {"name": "Creative Commons Remix", "value": "fab,creative-commons-remix"}, 
  {"name": "Creative Commons Share Alike", "value": "fab,creative-commons-sa"}, 
  {"name": "Creative Commons Sampling", "value": "fab,creative-commons-sampling"}, 
  {"name": "Creative Commons Sampling +", "value": "fab,creative-commons-sampling-plus"}, 
  {"name": "Creative Commons Share", "value": "fab,creative-commons-share"}, 
  {"name": "Creative Commons CC0", "value": "fab,creative-commons-zero"}, 
  {"name": "Credit Card (solid)", "value": "fas,credit-card"}, 
  {"name": "Credit Card (regular)", "value": "far,credit-card"}, 
  {"name": "Critical Role", "value": "fab,critical-role"}, 
  {"name": "crop", "value": "fas,crop"}, 
  {"name": "Alternate Crop", "value": "fas,crop-alt"}, 
  {"name": "Cross", "value": "fas,cross"}, 
  {"name": "Crosshairs", "value": "fas,crosshairs"}, 
  {"name": "Crow", "value": "fas,crow"}, 
  {"name": "Crown", "value": "fas,crown"}, 
  {"name": "CSS 3 Logo", "value": "fab,css3"}, 
  {"name": "Alternate CSS3 Logo", "value": "fab,css3-alt"}, 
  {"name": "Cube", "value": "fas,cube"}, 
  {"name": "Cubes", "value": "fas,cubes"}, 
  {"name": "Cut", "value": "fas,cut"}, 
  {"name": "Cuttlefish", "value": "fab,cuttlefish"}, 
  {"name": "Dungeons & Dragons", "value": "fab,d-and-d"}, 
  {"name": "D&D Beyond", "value": "fab,d-and-d-beyond"}, 
  {"name": "DashCube", "value": "fab,dashcube"}, 
  {"name": "Database", "value": "fas,database"}, 
  {"name": "Deaf", "value": "fas,deaf"}, 
  {"name": "Delicious Logo", "value": "fab,delicious"}, 
  {"name": "Democrat", "value": "fas,democrat"}, 
  {"name": "deploy.dog", "value": "fab,deploydog"}, 
  {"name": "Deskpro", "value": "fab,deskpro"}, 
  {"name": "Desktop", "value": "fas,desktop"}, 
  {"name": "DEV", "value": "fab,dev"}, 
  {"name": "deviantART", "value": "fab,deviantart"}, 
  {"name": "Dharmachakra", "value": "fas,dharmachakra"}, 
  {"name": "DHL", "value": "fab,dhl"}, 
  {"name": "Diagnoses", "value": "fas,diagnoses"}, 
  {"name": "Diaspora", "value": "fab,diaspora"}, 
  {"name": "Dice", "value": "fas,dice"}, 
  {"name": "Dice D20", "value": "fas,dice-d20"}, 
  {"name": "Dice D6", "value": "fas,dice-d6"}, 
  {"name": "Dice Five", "value": "fas,dice-five"}, 
  {"name": "Dice Four", "value": "fas,dice-four"}, 
  {"name": "Dice One", "value": "fas,dice-one"}, 
  {"name": "Dice Six", "value": "fas,dice-six"}, 
  {"name": "Dice Three", "value": "fas,dice-three"}, 
  {"name": "Dice Two", "value": "fas,dice-two"}, 
  {"name": "Digg Logo", "value": "fab,digg"}, 
  {"name": "Digital Ocean", "value": "fab,digital-ocean"}, 
  {"name": "Digital Tachograph", "value": "fas,digital-tachograph"}, 
  {"name": "Directions", "value": "fas,directions"}, 
  {"name": "Discord", "value": "fab,discord"}, 
  {"name": "Discourse", "value": "fab,discourse"}, 
  {"name": "Divide", "value": "fas,divide"}, 
  {"name": "Dizzy Face (solid)", "value": "fas,dizzy"}, 
  {"name": "Dizzy Face (regular)", "value": "far,dizzy"}, 
  {"name": "DNA", "value": "fas,dna"}, 
  {"name": "DocHub", "value": "fab,dochub"}, 
  {"name": "Docker", "value": "fab,docker"}, 
  {"name": "Dog", "value": "fas,dog"}, 
  {"name": "Dollar Sign", "value": "fas,dollar-sign"}, 
  {"name": "Dolly", "value": "fas,dolly"}, 
  {"name": "Dolly Flatbed", "value": "fas,dolly-flatbed"}, 
  {"name": "Donate", "value": "fas,donate"}, 
  {"name": "Door Closed", "value": "fas,door-closed"}, 
  {"name": "Door Open", "value": "fas,door-open"}, 
  {"name": "Dot Circle (solid)", "value": "fas,dot-circle"}, 
  {"name": "Dot Circle (regular)", "value": "far,dot-circle"}, 
  {"name": "Dove", "value": "fas,dove"}, 
  {"name": "Download", "value": "fas,download"}, 
  {"name": "Draft2digital", "value": "fab,draft2digital"}, 
  {"name": "Drafting Compass", "value": "fas,drafting-compass"}, 
  {"name": "Dragon", "value": "fas,dragon"}, 
  {"name": "Draw Polygon", "value": "fas,draw-polygon"}, 
  {"name": "Dribbble", "value": "fab,dribbble"}, 
  {"name": "Dribbble Square", "value": "fab,dribbble-square"}, 
  {"name": "Dropbox", "value": "fab,dropbox"}, 
  {"name": "Drum", "value": "fas,drum"}, 
  {"name": "Drum Steelpan", "value": "fas,drum-steelpan"}, 
  {"name": "Drumstick with Bite Taken Out", "value": "fas,drumstick-bite"}, 
  {"name": "Drupal Logo", "value": "fab,drupal"}, 
  {"name": "Dumbbell", "value": "fas,dumbbell"}, 
  {"name": "Dumpster", "value": "fas,dumpster"}, 
  {"name": "Dumpster Fire", "value": "fas,dumpster-fire"}, 
  {"name": "Dungeon", "value": "fas,dungeon"}, 
  {"name": "Dyalog", "value": "fab,dyalog"}, 
  {"name": "Earlybirds", "value": "fab,earlybirds"}, 
  {"name": "eBay", "value": "fab,ebay"}, 
  {"name": "Edge Browser", "value": "fab,edge"}, 
  {"name": "Edit (solid)", "value": "fas,edit"}, 
  {"name": "Edit (regular)", "value": "far,edit"}, 
  {"name": "eject", "value": "fas,eject"}, 
  {"name": "Elementor", "value": "fab,elementor"}, 
  {"name": "Horizontal Ellipsis", "value": "fas,ellipsis-h"}, 
  {"name": "Vertical Ellipsis", "value": "fas,ellipsis-v"}, 
  {"name": "Ello", "value": "fab,ello"}, 
  {"name": "Ember", "value": "fab,ember"}, 
  {"name": "Galactic Empire", "value": "fab,empire"}, 
  {"name": "Envelope (solid)", "value": "fas,envelope"}, 
  {"name": "Envelope (regular)", "value": "far,envelope"}, 
  {"name": "Envelope Open (solid)", "value": "fas,envelope-open"}, 
  {"name": "Envelope Open (regular)", "value": "far,envelope-open"}, 
  {"name": "Envelope Open-text", "value": "fas,envelope-open-text"}, 
  {"name": "Envelope Square", "value": "fas,envelope-square"}, 
  {"name": "Envira Gallery", "value": "fab,envira"}, 
  {"name": "Equals", "value": "fas,equals"}, 
  {"name": "eraser", "value": "fas,eraser"}, 
  {"name": "Erlang", "value": "fab,erlang"}, 
  {"name": "Ethereum", "value": "fab,ethereum"}, 
  {"name": "Ethernet", "value": "fas,ethernet"}, 
  {"name": "Etsy", "value": "fab,etsy"}, 
  {"name": "Euro Sign", "value": "fas,euro-sign"}, 
  {"name": "Alternate Exchange", "value": "fas,exchange-alt"}, 
  {"name": "exclamation", "value": "fas,exclamation"}, 
  {"name": "Exclamation Circle", "value": "fas,exclamation-circle"}, 
  {"name": "Exclamation Triangle", "value": "fas,exclamation-triangle"}, 
  {"name": "Expand", "value": "fas,expand"}, 
  {"name": "Alternate Expand Arrows", "value": "fas,expand-arrows-alt"}, 
  {"name": "ExpeditedSSL", "value": "fab,expeditedssl"}, 
  {"name": "Alternate External Link", "value": "fas,external-link-alt"}, 
  {"name": "Alternate External Link Square", "value": "fas,external-link-square-alt"}, 
  {"name": "Eye (solid)", "value": "fas,eye"}, 
  {"name": "Eye (regular)", "value": "far,eye"}, 
  {"name": "Eye Dropper", "value": "fas,eye-dropper"}, 
  {"name": "Eye Slash (solid)", "value": "fas,eye-slash"}, 
  {"name": "Eye Slash (regular)", "value": "far,eye-slash"}, 
  {"name": "Facebook", "value": "fab,facebook"}, 
  {"name": "Facebook F", "value": "fab,facebook-f"}, 
  {"name": "Facebook Messenger", "value": "fab,facebook-messenger"}, 
  {"name": "Facebook Square", "value": "fab,facebook-square"}, 
  {"name": "Fantasy Flight-games", "value": "fab,fantasy-flight-games"}, 
  {"name": "fast-backward", "value": "fas,fast-backward"}, 
  {"name": "fast-forward", "value": "fas,fast-forward"}, 
  {"name": "Fax", "value": "fas,fax"}, 
  {"name": "Feather", "value": "fas,feather"}, 
  {"name": "Alternate Feather", "value": "fas,feather-alt"}, 
  {"name": "FedEx", "value": "fab,fedex"}, 
  {"name": "Fedora", "value": "fab,fedora"}, 
  {"name": "Female", "value": "fas,female"}, 
  {"name": "fighter-jet", "value": "fas,fighter-jet"}, 
  {"name": "Figma", "value": "fab,figma"}, 
  {"name": "File (solid)", "value": "fas,file"}, 
  {"name": "File (regular)", "value": "far,file"}, 
  {"name": "Alternate File (solid)", "value": "fas,file-alt"}, 
  {"name": "Alternate File (regular)", "value": "far,file-alt"}, 
  {"name": "Archive File (solid)", "value": "fas,file-archive"}, 
  {"name": "Archive File (regular)", "value": "far,file-archive"}, 
  {"name": "Audio File (solid)", "value": "fas,file-audio"}, 
  {"name": "Audio File (regular)", "value": "far,file-audio"}, 
  {"name": "Code File (solid)", "value": "fas,file-code"}, 
  {"name": "Code File (regular)", "value": "far,file-code"}, 
  {"name": "File Contract", "value": "fas,file-contract"}, 
  {"name": "File CSV", "value": "fas,file-csv"}, 
  {"name": "File Download", "value": "fas,file-download"}, 
  {"name": "Excel File (solid)", "value": "fas,file-excel"}, 
  {"name": "Excel File (regular)", "value": "far,file-excel"}, 
  {"name": "File Export", "value": "fas,file-export"}, 
  {"name": "Image File (solid)", "value": "fas,file-image"}, 
  {"name": "Image File (regular)", "value": "far,file-image"}, 
  {"name": "File Import", "value": "fas,file-import"}, 
  {"name": "File Invoice", "value": "fas,file-invoice"}, 
  {"name": "File Invoice with US Dollar", "value": "fas,file-invoice-dollar"}, 
  {"name": "Medical File", "value": "fas,file-medical"}, 
  {"name": "Alternate Medical File", "value": "fas,file-medical-alt"}, 
  {"name": "PDF File (solid)", "value": "fas,file-pdf"}, 
  {"name": "PDF File (regular)", "value": "far,file-pdf"}, 
  {"name": "Powerpoint File (solid)", "value": "fas,file-powerpoint"}, 
  {"name": "Powerpoint File (regular)", "value": "far,file-powerpoint"}, 
  {"name": "File Prescription", "value": "fas,file-prescription"}, 
  {"name": "File Signature", "value": "fas,file-signature"}, 
  {"name": "File Upload", "value": "fas,file-upload"}, 
  {"name": "Video File (solid)", "value": "fas,file-video"}, 
  {"name": "Video File (regular)", "value": "far,file-video"}, 
  {"name": "Word File (solid)", "value": "fas,file-word"}, 
  {"name": "Word File (regular)", "value": "far,file-word"}, 
  {"name": "Fill", "value": "fas,fill"}, 
  {"name": "Fill Drip", "value": "fas,fill-drip"}, 
  {"name": "Film", "value": "fas,film"}, 
  {"name": "Filter", "value": "fas,filter"}, 
  {"name": "Fingerprint", "value": "fas,fingerprint"}, 
  {"name": "fire", "value": "fas,fire"}, 
  {"name": "Alternate Fire", "value": "fas,fire-alt"}, 
  {"name": "fire-extinguisher", "value": "fas,fire-extinguisher"}, 
  {"name": "Firefox", "value": "fab,firefox"}, 
  {"name": "First Aid", "value": "fas,first-aid"}, 
  {"name": "First Order", "value": "fab,first-order"}, 
  {"name": "Alternate First Order", "value": "fab,first-order-alt"}, 
  {"name": "firstdraft", "value": "fab,firstdraft"}, 
  {"name": "Fish", "value": "fas,fish"}, 
  {"name": "Raised Fist", "value": "fas,fist-raised"}, 
  {"name": "flag (solid)", "value": "fas,flag"}, 
  {"name": "flag (regular)", "value": "far,flag"}, 
  {"name": "flag-checkered", "value": "fas,flag-checkered"}, 
  {"name": "United States of America Flag", "value": "fas,flag-usa"}, 
  {"name": "Flask", "value": "fas,flask"}, 
  {"name": "Flickr", "value": "fab,flickr"}, 
  {"name": "Flipboard", "value": "fab,flipboard"}, 
  {"name": "Flushed Face (solid)", "value": "fas,flushed"}, 
  {"name": "Flushed Face (regular)", "value": "far,flushed"}, 
  {"name": "Fly", "value": "fab,fly"}, 
  {"name": "Folder (solid)", "value": "fas,folder"}, 
  {"name": "Folder (regular)", "value": "far,folder"}, 
  {"name": "Folder Minus", "value": "fas,folder-minus"}, 
  {"name": "Folder Open (solid)", "value": "fas,folder-open"}, 
  {"name": "Folder Open (regular)", "value": "far,folder-open"}, 
  {"name": "Folder Plus", "value": "fas,folder-plus"}, 
  {"name": "font", "value": "fas,font"}, 
  {"name": "Font Awesome", "value": "fab,font-awesome"}, 
  {"name": "Alternate Font Awesome", "value": "fab,font-awesome-alt"}, 
  {"name": "Font Awesome Flag", "value": "fab,font-awesome-flag"}, 
  {"name": "Font Awesome Full Logo (regular)", "value": "far,font-awesome-logo-full"}, 
  {"name": "Font Awesome Full Logo (solid)", "value": "fas,font-awesome-logo-full"}, 
  {"name": "Font Awesome Full Logo (brands)", "value": "fab,font-awesome-logo-full"}, 
  {"name": "Fonticons", "value": "fab,fonticons"}, 
  {"name": "Fonticons Fi", "value": "fab,fonticons-fi"}, 
  {"name": "Football Ball", "value": "fas,football-ball"}, 
  {"name": "Fort Awesome", "value": "fab,fort-awesome"}, 
  {"name": "Alternate Fort Awesome", "value": "fab,fort-awesome-alt"}, 
  {"name": "Forumbee", "value": "fab,forumbee"}, 
  {"name": "forward", "value": "fas,forward"}, 
  {"name": "Foursquare", "value": "fab,foursquare"}, 
  {"name": "Free Code Camp", "value": "fab,free-code-camp"}, 
  {"name": "FreeBSD", "value": "fab,freebsd"}, 
  {"name": "Frog", "value": "fas,frog"}, 
  {"name": "Frowning Face (solid)", "value": "fas,frown"}, 
  {"name": "Frowning Face (regular)", "value": "far,frown"}, 
  {"name": "Frowning Face With Open Mouth (solid)", "value": "fas,frown-open"}, 
  {"name": "Frowning Face With Open Mouth (regular)", "value": "far,frown-open"}, 
  {"name": "Fulcrum", "value": "fab,fulcrum"}, 
  {"name": "Funnel Dollar", "value": "fas,funnel-dollar"}, 
  {"name": "Futbol (solid)", "value": "fas,futbol"}, 
  {"name": "Futbol (regular)", "value": "far,futbol"}, 
  {"name": "Galactic Republic", "value": "fab,galactic-republic"}, 
  {"name": "Galactic Senate", "value": "fab,galactic-senate"}, 
  {"name": "Gamepad", "value": "fas,gamepad"}, 
  {"name": "Gas Pump", "value": "fas,gas-pump"}, 
  {"name": "Gavel", "value": "fas,gavel"}, 
  {"name": "Gem (solid)", "value": "fas,gem"}, 
  {"name": "Gem (regular)", "value": "far,gem"}, 
  {"name": "Genderless", "value": "fas,genderless"}, 
  {"name": "Get Pocket", "value": "fab,get-pocket"}, 
  {"name": "GG Currency", "value": "fab,gg"}, 
  {"name": "GG Currency Circle", "value": "fab,gg-circle"}, 
  {"name": "Ghost", "value": "fas,ghost"}, 
  {"name": "gift", "value": "fas,gift"}, 
  {"name": "Gifts", "value": "fas,gifts"}, 
  {"name": "Git", "value": "fab,git"}, 
  {"name": "Git Square", "value": "fab,git-square"}, 
  {"name": "GitHub", "value": "fab,github"}, 
  {"name": "Alternate GitHub", "value": "fab,github-alt"}, 
  {"name": "GitHub Square", "value": "fab,github-square"}, 
  {"name": "GitKraken", "value": "fab,gitkraken"}, 
  {"name": "GitLab", "value": "fab,gitlab"}, 
  {"name": "Gitter", "value": "fab,gitter"}, 
  {"name": "Glass Cheers", "value": "fas,glass-cheers"}, 
  {"name": "Martini Glass", "value": "fas,glass-martini"}, 
  {"name": "Alternate Glass Martini", "value": "fas,glass-martini-alt"}, 
  {"name": "Glass Whiskey", "value": "fas,glass-whiskey"}, 
  {"name": "Glasses", "value": "fas,glasses"}, 
  {"name": "Glide", "value": "fab,glide"}, 
  {"name": "Glide G", "value": "fab,glide-g"}, 
  {"name": "Globe", "value": "fas,globe"}, 
  {"name": "Globe with Africa shown", "value": "fas,globe-africa"}, 
  {"name": "Globe with Americas shown", "value": "fas,globe-americas"}, 
  {"name": "Globe with Asia shown", "value": "fas,globe-asia"}, 
  {"name": "Globe with Europe shown", "value": "fas,globe-europe"}, 
  {"name": "Gofore", "value": "fab,gofore"}, 
  {"name": "Golf Ball", "value": "fas,golf-ball"}, 
  {"name": "Goodreads", "value": "fab,goodreads"}, 
  {"name": "Goodreads G", "value": "fab,goodreads-g"}, 
  {"name": "Google Logo", "value": "fab,google"}, 
  {"name": "Google Drive", "value": "fab,google-drive"}, 
  {"name": "Google Play", "value": "fab,google-play"}, 
  {"name": "Google Plus", "value": "fab,google-plus"}, 
  {"name": "Google Plus G", "value": "fab,google-plus-g"}, 
  {"name": "Google Plus Square", "value": "fab,google-plus-square"}, 
  {"name": "Google Wallet", "value": "fab,google-wallet"}, 
  {"name": "Gopuram", "value": "fas,gopuram"}, 
  {"name": "Graduation Cap", "value": "fas,graduation-cap"}, 
  {"name": "Gratipay (Gittip)", "value": "fab,gratipay"}, 
  {"name": "Grav", "value": "fab,grav"}, 
  {"name": "Greater Than", "value": "fas,greater-than"}, 
  {"name": "Greater Than Equal To", "value": "fas,greater-than-equal"}, 
  {"name": "Grimacing Face (solid)", "value": "fas,grimace"}, 
  {"name": "Grimacing Face (regular)", "value": "far,grimace"}, 
  {"name": "Grinning Face (solid)", "value": "fas,grin"}, 
  {"name": "Grinning Face (regular)", "value": "far,grin"}, 
  {"name": "Alternate Grinning Face (solid)", "value": "fas,grin-alt"}, 
  {"name": "Alternate Grinning Face (regular)", "value": "far,grin-alt"}, 
  {"name": "Grinning Face With Smiling Eyes (solid)", "value": "fas,grin-beam"}, 
  {"name": "Grinning Face With Smiling Eyes (regular)", "value": "far,grin-beam"}, 
  {"name": "Grinning Face With Sweat (solid)", "value": "fas,grin-beam-sweat"}, 
  {"name": "Grinning Face With Sweat (regular)", "value": "far,grin-beam-sweat"}, 
  {"name": "Smiling Face With Heart-Eyes (solid)", "value": "fas,grin-hearts"}, 
  {"name": "Smiling Face With Heart-Eyes (regular)", "value": "far,grin-hearts"}, 
  {"name": "Grinning Squinting Face (solid)", "value": "fas,grin-squint"}, 
  {"name": "Grinning Squinting Face (regular)", "value": "far,grin-squint"}, 
  {"name": "Rolling on the Floor Laughing (solid)", "value": "fas,grin-squint-tears"}, 
  {"name": "Rolling on the Floor Laughing (regular)", "value": "far,grin-squint-tears"}, 
  {"name": "Star-Struck (solid)", "value": "fas,grin-stars"}, 
  {"name": "Star-Struck (regular)", "value": "far,grin-stars"}, 
  {"name": "Face With Tears of Joy (solid)", "value": "fas,grin-tears"}, 
  {"name": "Face With Tears of Joy (regular)", "value": "far,grin-tears"}, 
  {"name": "Face With Tongue (solid)", "value": "fas,grin-tongue"}, 
  {"name": "Face With Tongue (regular)", "value": "far,grin-tongue"}, 
  {"name": "Squinting Face With Tongue (solid)", "value": "fas,grin-tongue-squint"}, 
  {"name": "Squinting Face With Tongue (regular)", "value": "far,grin-tongue-squint"}, 
  {"name": "Winking Face With Tongue (solid)", "value": "fas,grin-tongue-wink"}, 
  {"name": "Winking Face With Tongue (regular)", "value": "far,grin-tongue-wink"}, 
  {"name": "Grinning Winking Face (solid)", "value": "fas,grin-wink"}, 
  {"name": "Grinning Winking Face (regular)", "value": "far,grin-wink"}, 
  {"name": "Grip Horizontal", "value": "fas,grip-horizontal"}, 
  {"name": "Grip Lines", "value": "fas,grip-lines"}, 
  {"name": "Grip Lines Vertical", "value": "fas,grip-lines-vertical"}, 
  {"name": "Grip Vertical", "value": "fas,grip-vertical"}, 
  {"name": "Gripfire, Inc.", "value": "fab,gripfire"}, 
  {"name": "Grunt", "value": "fab,grunt"}, 
  {"name": "Guitar", "value": "fas,guitar"}, 
  {"name": "Gulp", "value": "fab,gulp"}, 
  {"name": "H Square", "value": "fas,h-square"}, 
  {"name": "Hacker News", "value": "fab,hacker-news"}, 
  {"name": "Hacker News Square", "value": "fab,hacker-news-square"}, 
  {"name": "Hackerrank", "value": "fab,hackerrank"}, 
  {"name": "Hammer", "value": "fas,hammer"}, 
  {"name": "Hamsa", "value": "fas,hamsa"}, 
  {"name": "Hand Holding", "value": "fas,hand-holding"}, 
  {"name": "Hand Holding Heart", "value": "fas,hand-holding-heart"}, 
  {"name": "Hand Holding US Dollar", "value": "fas,hand-holding-usd"}, 
  {"name": "Lizard (Hand) (solid)", "value": "fas,hand-lizard"}, 
  {"name": "Lizard (Hand) (regular)", "value": "far,hand-lizard"}, 
  {"name": "Paper (Hand) (solid)", "value": "fas,hand-paper"}, 
  {"name": "Paper (Hand) (regular)", "value": "far,hand-paper"}, 
  {"name": "Peace (Hand) (solid)", "value": "fas,hand-peace"}, 
  {"name": "Peace (Hand) (regular)", "value": "far,hand-peace"}, 
  {"name": "Hand Pointing Down (solid)", "value": "fas,hand-point-down"}, 
  {"name": "Hand Pointing Down (regular)", "value": "far,hand-point-down"}, 
  {"name": "Hand Pointing Left (solid)", "value": "fas,hand-point-left"}, 
  {"name": "Hand Pointing Left (regular)", "value": "far,hand-point-left"}, 
  {"name": "Hand Pointing Right (solid)", "value": "fas,hand-point-right"}, 
  {"name": "Hand Pointing Right (regular)", "value": "far,hand-point-right"}, 
  {"name": "Hand Pointing Up (solid)", "value": "fas,hand-point-up"}, 
  {"name": "Hand Pointing Up (regular)", "value": "far,hand-point-up"}, 
  {"name": "Pointer (Hand) (solid)", "value": "fas,hand-pointer"}, 
  {"name": "Pointer (Hand) (regular)", "value": "far,hand-pointer"}, 
  {"name": "Rock (Hand) (solid)", "value": "fas,hand-rock"}, 
  {"name": "Rock (Hand) (regular)", "value": "far,hand-rock"}, 
  {"name": "Scissors (Hand) (solid)", "value": "fas,hand-scissors"}, 
  {"name": "Scissors (Hand) (regular)", "value": "far,hand-scissors"}, 
  {"name": "Spock (Hand) (solid)", "value": "fas,hand-spock"}, 
  {"name": "Spock (Hand) (regular)", "value": "far,hand-spock"}, 
  {"name": "Hands", "value": "fas,hands"}, 
  {"name": "Helping Hands", "value": "fas,hands-helping"}, 
  {"name": "Handshake (solid)", "value": "fas,handshake"}, 
  {"name": "Handshake (regular)", "value": "far,handshake"}, 
  {"name": "Hanukiah", "value": "fas,hanukiah"}, 
  {"name": "Hashtag", "value": "fas,hashtag"}, 
  {"name": "Wizards Hat", "value": "fas,hat-wizard"}, 
  {"name": "Haykal", "value": "fas,haykal"}, 
  {"name": "HDD (solid)", "value": "fas,hdd"}, 
  {"name": "HDD (regular)", "value": "far,hdd"}, 
  {"name": "heading", "value": "fas,heading"}, 
  {"name": "headphones", "value": "fas,headphones"}, 
  {"name": "Alternate Headphones", "value": "fas,headphones-alt"}, 
  {"name": "Headset", "value": "fas,headset"}, 
  {"name": "Heart (solid)", "value": "fas,heart"}, 
  {"name": "Heart (regular)", "value": "far,heart"}, 
  {"name": "Heart Broken", "value": "fas,heart-broken"}, 
  {"name": "Heartbeat", "value": "fas,heartbeat"}, 
  {"name": "Helicopter", "value": "fas,helicopter"}, 
  {"name": "Highlighter", "value": "fas,highlighter"}, 
  {"name": "Hiking", "value": "fas,hiking"}, 
  {"name": "Hippo", "value": "fas,hippo"}, 
  {"name": "Hips", "value": "fab,hips"}, 
  {"name": "HireAHelper", "value": "fab,hire-a-helper"}, 
  {"name": "History", "value": "fas,history"}, 
  {"name": "Hockey Puck", "value": "fas,hockey-puck"}, 
  {"name": "Holly Berry", "value": "fas,holly-berry"}, 
  {"name": "home", "value": "fas,home"}, 
  {"name": "Hooli", "value": "fab,hooli"}, 
  {"name": "Hornbill", "value": "fab,hornbill"}, 
  {"name": "Horse", "value": "fas,horse"}, 
  {"name": "Horse Head", "value": "fas,horse-head"}, 
  {"name": "hospital (solid)", "value": "fas,hospital"}, 
  {"name": "hospital (regular)", "value": "far,hospital"}, 
  {"name": "Alternate Hospital", "value": "fas,hospital-alt"}, 
  {"name": "Hospital Symbol", "value": "fas,hospital-symbol"}, 
  {"name": "Hot Tub", "value": "fas,hot-tub"}, 
  {"name": "Hotel", "value": "fas,hotel"}, 
  {"name": "Hotjar", "value": "fab,hotjar"}, 
  {"name": "Hourglass (solid)", "value": "fas,hourglass"}, 
  {"name": "Hourglass (regular)", "value": "far,hourglass"}, 
  {"name": "Hourglass End", "value": "fas,hourglass-end"}, 
  {"name": "Hourglass Half", "value": "fas,hourglass-half"}, 
  {"name": "Hourglass Start", "value": "fas,hourglass-start"}, 
  {"name": "Damaged House", "value": "fas,house-damage"}, 
  {"name": "Houzz", "value": "fab,houzz"}, 
  {"name": "Hryvnia", "value": "fas,hryvnia"}, 
  {"name": "HTML 5 Logo", "value": "fab,html5"}, 
  {"name": "HubSpot", "value": "fab,hubspot"}, 
  {"name": "I Beam Cursor", "value": "fas,i-cursor"}, 
  {"name": "Icicles", "value": "fas,icicles"}, 
  {"name": "Identification Badge (solid)", "value": "fas,id-badge"}, 
  {"name": "Identification Badge (regular)", "value": "far,id-badge"}, 
  {"name": "Identification Card (solid)", "value": "fas,id-card"}, 
  {"name": "Identification Card (regular)", "value": "far,id-card"}, 
  {"name": "Alternate Identification Card", "value": "fas,id-card-alt"}, 
  {"name": "Igloo", "value": "fas,igloo"}, 
  {"name": "Image (solid)", "value": "fas,image"}, 
  {"name": "Image (regular)", "value": "far,image"}, 
  {"name": "Images (solid)", "value": "fas,images"}, 
  {"name": "Images (regular)", "value": "far,images"}, 
  {"name": "IMDB", "value": "fab,imdb"}, 
  {"name": "inbox", "value": "fas,inbox"}, 
  {"name": "Indent", "value": "fas,indent"}, 
  {"name": "Industry", "value": "fas,industry"}, 
  {"name": "Infinity", "value": "fas,infinity"}, 
  {"name": "Info", "value": "fas,info"}, 
  {"name": "Info Circle", "value": "fas,info-circle"}, 
  {"name": "Instagram", "value": "fab,instagram"}, 
  {"name": "Intercom", "value": "fab,intercom"}, 
  {"name": "Internet-explorer", "value": "fab,internet-explorer"}, 
  {"name": "InVision", "value": "fab,invision"}, 
  {"name": "ioxhost", "value": "fab,ioxhost"}, 
  {"name": "italic", "value": "fas,italic"}, 
  {"name": "iTunes", "value": "fab,itunes"}, 
  {"name": "Itunes Note", "value": "fab,itunes-note"}, 
  {"name": "Java", "value": "fab,java"}, 
  {"name": "Jedi", "value": "fas,jedi"}, 
  {"name": "Jedi Order", "value": "fab,jedi-order"}, 
  {"name": "Jenkis", "value": "fab,jenkins"}, 
  {"name": "Jira", "value": "fab,jira"}, 
  {"name": "Joget", "value": "fab,joget"}, 
  {"name": "Joint", "value": "fas,joint"}, 
  {"name": "Joomla Logo", "value": "fab,joomla"}, 
  {"name": "Journal of the Whills", "value": "fas,journal-whills"}, 
  {"name": "JavaScript (JS)", "value": "fab,js"}, 
  {"name": "JavaScript (JS) Square", "value": "fab,js-square"}, 
  {"name": "jsFiddle", "value": "fab,jsfiddle"}, 
  {"name": "Kaaba", "value": "fas,kaaba"}, 
  {"name": "Kaggle", "value": "fab,kaggle"}, 
  {"name": "key", "value": "fas,key"}, 
  {"name": "Keybase", "value": "fab,keybase"}, 
  {"name": "Keyboard (solid)", "value": "fas,keyboard"}, 
  {"name": "Keyboard (regular)", "value": "far,keyboard"}, 
  {"name": "KeyCDN", "value": "fab,keycdn"}, 
  {"name": "Khanda", "value": "fas,khanda"}, 
  {"name": "Kickstarter", "value": "fab,kickstarter"}, 
  {"name": "Kickstarter K", "value": "fab,kickstarter-k"}, 
  {"name": "Kissing Face (solid)", "value": "fas,kiss"}, 
  {"name": "Kissing Face (regular)", "value": "far,kiss"}, 
  {"name": "Kissing Face With Smiling Eyes (solid)", "value": "fas,kiss-beam"}, 
  {"name": "Kissing Face With Smiling Eyes (regular)", "value": "far,kiss-beam"}, 
  {"name": "Face Blowing a Kiss (solid)", "value": "fas,kiss-wink-heart"}, 
  {"name": "Face Blowing a Kiss (regular)", "value": "far,kiss-wink-heart"}, 
  {"name": "Kiwi Bird", "value": "fas,kiwi-bird"}, 
  {"name": "KORVUE", "value": "fab,korvue"}, 
  {"name": "Landmark", "value": "fas,landmark"}, 
  {"name": "Language", "value": "fas,language"}, 
  {"name": "Laptop", "value": "fas,laptop"}, 
  {"name": "Laptop Code", "value": "fas,laptop-code"}, 
  {"name": "Laravel", "value": "fab,laravel"}, 
  {"name": "last.fm", "value": "fab,lastfm"}, 
  {"name": "last.fm Square", "value": "fab,lastfm-square"}, 
  {"name": "Grinning Face With Big Eyes (solid)", "value": "fas,laugh"}, 
  {"name": "Grinning Face With Big Eyes (regular)", "value": "far,laugh"}, 
  {"name": "Laugh Face with Beaming Eyes (solid)", "value": "fas,laugh-beam"}, 
  {"name": "Laugh Face with Beaming Eyes (regular)", "value": "far,laugh-beam"}, 
  {"name": "Laughing Squinting Face (solid)", "value": "fas,laugh-squint"}, 
  {"name": "Laughing Squinting Face (regular)", "value": "far,laugh-squint"}, 
  {"name": "Laughing Winking Face (solid)", "value": "fas,laugh-wink"}, 
  {"name": "Laughing Winking Face (regular)", "value": "far,laugh-wink"}, 
  {"name": "Layer Group", "value": "fas,layer-group"}, 
  {"name": "leaf", "value": "fas,leaf"}, 
  {"name": "Leanpub", "value": "fab,leanpub"}, 
  {"name": "Lemon (solid)", "value": "fas,lemon"}, 
  {"name": "Lemon (regular)", "value": "far,lemon"}, 
  {"name": "Less", "value": "fab,less"}, 
  {"name": "Less Than", "value": "fas,less-than"}, 
  {"name": "Less Than Equal To", "value": "fas,less-than-equal"}, 
  {"name": "Alternate Level Down", "value": "fas,level-down-alt"}, 
  {"name": "Alternate Level Up", "value": "fas,level-up-alt"}, 
  {"name": "Life Ring (solid)", "value": "fas,life-ring"}, 
  {"name": "Life Ring (regular)", "value": "far,life-ring"}, 
  {"name": "Lightbulb (solid)", "value": "fas,lightbulb"}, 
  {"name": "Lightbulb (regular)", "value": "far,lightbulb"}, 
  {"name": "Line", "value": "fab,line"}, 
  {"name": "Link", "value": "fas,link"}, 
  {"name": "LinkedIn", "value": "fab,linkedin"}, 
  {"name": "LinkedIn In", "value": "fab,linkedin-in"}, 
  {"name": "Linode", "value": "fab,linode"}, 
  {"name": "Linux", "value": "fab,linux"}, 
  {"name": "Turkish Lira Sign", "value": "fas,lira-sign"}, 
  {"name": "List", "value": "fas,list"}, 
  {"name": "Alternate List (solid)", "value": "fas,list-alt"}, 
  {"name": "Alternate List (regular)", "value": "far,list-alt"}, 
  {"name": "list-ol", "value": "fas,list-ol"}, 
  {"name": "list-ul", "value": "fas,list-ul"}, 
  {"name": "location-arrow", "value": "fas,location-arrow"}, 
  {"name": "lock", "value": "fas,lock"}, 
  {"name": "Lock Open", "value": "fas,lock-open"}, 
  {"name": "Alternate Long Arrow Down", "value": "fas,long-arrow-alt-down"}, 
  {"name": "Alternate Long Arrow Left", "value": "fas,long-arrow-alt-left"}, 
  {"name": "Alternate Long Arrow Right", "value": "fas,long-arrow-alt-right"}, 
  {"name": "Alternate Long Arrow Up", "value": "fas,long-arrow-alt-up"}, 
  {"name": "Low Vision", "value": "fas,low-vision"}, 
  {"name": "Luggage Cart", "value": "fas,luggage-cart"}, 
  {"name": "lyft", "value": "fab,lyft"}, 
  {"name": "Magento", "value": "fab,magento"}, 
  {"name": "magic", "value": "fas,magic"}, 
  {"name": "magnet", "value": "fas,magnet"}, 
  {"name": "Mail Bulk", "value": "fas,mail-bulk"}, 
  {"name": "Mailchimp", "value": "fab,mailchimp"}, 
  {"name": "Male", "value": "fas,male"}, 
  {"name": "Mandalorian", "value": "fab,mandalorian"}, 
  {"name": "Map (solid)", "value": "fas,map"}, 
  {"name": "Map (regular)", "value": "far,map"}, 
  {"name": "Map Marked", "value": "fas,map-marked"}, 
  {"name": "Alternate Map Marked", "value": "fas,map-marked-alt"}, 
  {"name": "map-marker", "value": "fas,map-marker"}, 
  {"name": "Alternate Map Marker", "value": "fas,map-marker-alt"}, 
  {"name": "Map Pin", "value": "fas,map-pin"}, 
  {"name": "Map Signs", "value": "fas,map-signs"}, 
  {"name": "Markdown", "value": "fab,markdown"}, 
  {"name": "Marker", "value": "fas,marker"}, 
  {"name": "Mars", "value": "fas,mars"}, 
  {"name": "Mars Double", "value": "fas,mars-double"}, 
  {"name": "Mars Stroke", "value": "fas,mars-stroke"}, 
  {"name": "Mars Stroke Horizontal", "value": "fas,mars-stroke-h"}, 
  {"name": "Mars Stroke Vertical", "value": "fas,mars-stroke-v"}, 
  {"name": "Mask", "value": "fas,mask"}, 
  {"name": "Mastodon", "value": "fab,mastodon"}, 
  {"name": "MaxCDN", "value": "fab,maxcdn"}, 
  {"name": "Medal", "value": "fas,medal"}, 
  {"name": "MedApps", "value": "fab,medapps"}, 
  {"name": "Medium", "value": "fab,medium"}, 
  {"name": "Medium M", "value": "fab,medium-m"}, 
  {"name": "medkit", "value": "fas,medkit"}, 
  {"name": "MRT", "value": "fab,medrt"}, 
  {"name": "Meetup", "value": "fab,meetup"}, 
  {"name": "Megaport", "value": "fab,megaport"}, 
  {"name": "Neutral Face (solid)", "value": "fas,meh"}, 
  {"name": "Neutral Face (regular)", "value": "far,meh"}, 
  {"name": "Face Without Mouth (solid)", "value": "fas,meh-blank"}, 
  {"name": "Face Without Mouth (regular)", "value": "far,meh-blank"}, 
  {"name": "Face With Rolling Eyes (solid)", "value": "fas,meh-rolling-eyes"}, 
  {"name": "Face With Rolling Eyes (regular)", "value": "far,meh-rolling-eyes"}, 
  {"name": "Memory", "value": "fas,memory"}, 
  {"name": "Mendeley", "value": "fab,mendeley"}, 
  {"name": "Menorah", "value": "fas,menorah"}, 
  {"name": "Mercury", "value": "fas,mercury"}, 
  {"name": "Meteor", "value": "fas,meteor"}, 
  {"name": "Microchip", "value": "fas,microchip"}, 
  {"name": "microphone", "value": "fas,microphone"}, 
  {"name": "Alternate Microphone", "value": "fas,microphone-alt"}, 
  {"name": "Alternate Microphone Slash", "value": "fas,microphone-alt-slash"}, 
  {"name": "Microphone Slash", "value": "fas,microphone-slash"}, 
  {"name": "Microscope", "value": "fas,microscope"}, 
  {"name": "Microsoft", "value": "fab,microsoft"}, 
  {"name": "minus", "value": "fas,minus"}, 
  {"name": "Minus Circle", "value": "fas,minus-circle"}, 
  {"name": "Minus Square (solid)", "value": "fas,minus-square"}, 
  {"name": "Minus Square (regular)", "value": "far,minus-square"}, 
  {"name": "Mitten", "value": "fas,mitten"}, 
  {"name": "Mix", "value": "fab,mix"}, 
  {"name": "Mixcloud", "value": "fab,mixcloud"}, 
  {"name": "Mizuni", "value": "fab,mizuni"}, 
  {"name": "Mobile Phone", "value": "fas,mobile"}, 
  {"name": "Alternate Mobile", "value": "fas,mobile-alt"}, 
  {"name": "MODX", "value": "fab,modx"}, 
  {"name": "Monero", "value": "fab,monero"}, 
  {"name": "Money Bill", "value": "fas,money-bill"}, 
  {"name": "Alternate Money Bill (solid)", "value": "fas,money-bill-alt"}, 
  {"name": "Alternate Money Bill (regular)", "value": "far,money-bill-alt"}, 
  {"name": "Wavy Money Bill", "value": "fas,money-bill-wave"}, 
  {"name": "Alternate Wavy Money Bill", "value": "fas,money-bill-wave-alt"}, 
  {"name": "Money Check", "value": "fas,money-check"}, 
  {"name": "Alternate Money Check", "value": "fas,money-check-alt"}, 
  {"name": "Monument", "value": "fas,monument"}, 
  {"name": "Moon (solid)", "value": "fas,moon"}, 
  {"name": "Moon (regular)", "value": "far,moon"}, 
  {"name": "Mortar Pestle", "value": "fas,mortar-pestle"}, 
  {"name": "Mosque", "value": "fas,mosque"}, 
  {"name": "Motorcycle", "value": "fas,motorcycle"}, 
  {"name": "Mountain", "value": "fas,mountain"}, 
  {"name": "Mouse Pointer", "value": "fas,mouse-pointer"}, 
  {"name": "Mug Hot", "value": "fas,mug-hot"}, 
  {"name": "Music", "value": "fas,music"}, 
  {"name": "Napster", "value": "fab,napster"}, 
  {"name": "Neos", "value": "fab,neos"}, 
  {"name": "Wired Network", "value": "fas,network-wired"}, 
  {"name": "Neuter", "value": "fas,neuter"}, 
  {"name": "Newspaper (solid)", "value": "fas,newspaper"}, 
  {"name": "Newspaper (regular)", "value": "far,newspaper"}, 
  {"name": "Nimblr", "value": "fab,nimblr"}, 
  {"name": "Nintendo Switch", "value": "fab,nintendo-switch"}, 
  {"name": "Node.js", "value": "fab,node"}, 
  {"name": "Node.js JS", "value": "fab,node-js"}, 
  {"name": "Not Equal", "value": "fas,not-equal"}, 
  {"name": "Medical Notes", "value": "fas,notes-medical"}, 
  {"name": "npm", "value": "fab,npm"}, 
  {"name": "NS8", "value": "fab,ns8"}, 
  {"name": "Nutritionix", "value": "fab,nutritionix"}, 
  {"name": "Object Group (solid)", "value": "fas,object-group"}, 
  {"name": "Object Group (regular)", "value": "far,object-group"}, 
  {"name": "Object Ungroup (solid)", "value": "fas,object-ungroup"}, 
  {"name": "Object Ungroup (regular)", "value": "far,object-ungroup"}, 
  {"name": "Odnoklassniki", "value": "fab,odnoklassniki"}, 
  {"name": "Odnoklassniki Square", "value": "fab,odnoklassniki-square"}, 
  {"name": "Oil Can", "value": "fas,oil-can"}, 
  {"name": "Old Republic", "value": "fab,old-republic"}, 
  {"name": "Om", "value": "fas,om"}, 
  {"name": "OpenCart", "value": "fab,opencart"}, 
  {"name": "OpenID", "value": "fab,openid"}, 
  {"name": "Opera", "value": "fab,opera"}, 
  {"name": "Optin Monster", "value": "fab,optin-monster"}, 
  {"name": "Open Source Initiative", "value": "fab,osi"}, 
  {"name": "Otter", "value": "fas,otter"}, 
  {"name": "Outdent", "value": "fas,outdent"}, 
  {"name": "page4 Corporation", "value": "fab,page4"}, 
  {"name": "Pagelines", "value": "fab,pagelines"}, 
  {"name": "Paint Brush", "value": "fas,paint-brush"}, 
  {"name": "Paint Roller", "value": "fas,paint-roller"}, 
  {"name": "Palette", "value": "fas,palette"}, 
  {"name": "Palfed", "value": "fab,palfed"}, 
  {"name": "Pallet", "value": "fas,pallet"}, 
  {"name": "Paper Plane (solid)", "value": "fas,paper-plane"}, 
  {"name": "Paper Plane (regular)", "value": "far,paper-plane"}, 
  {"name": "Paperclip", "value": "fas,paperclip"}, 
  {"name": "Parachute Box", "value": "fas,parachute-box"}, 
  {"name": "paragraph", "value": "fas,paragraph"}, 
  {"name": "Parking", "value": "fas,parking"}, 
  {"name": "Passport", "value": "fas,passport"}, 
  {"name": "Pastafarianism", "value": "fas,pastafarianism"}, 
  {"name": "Paste", "value": "fas,paste"}, 
  {"name": "Patreon", "value": "fab,patreon"}, 
  {"name": "pause", "value": "fas,pause"}, 
  {"name": "Pause Circle (solid)", "value": "fas,pause-circle"}, 
  {"name": "Pause Circle (regular)", "value": "far,pause-circle"}, 
  {"name": "Paw", "value": "fas,paw"}, 
  {"name": "Paypal", "value": "fab,paypal"}, 
  {"name": "Peace", "value": "fas,peace"}, 
  {"name": "Pen", "value": "fas,pen"}, 
  {"name": "Alternate Pen", "value": "fas,pen-alt"}, 
  {"name": "Pen Fancy", "value": "fas,pen-fancy"}, 
  {"name": "Pen Nib", "value": "fas,pen-nib"}, 
  {"name": "Pen Square", "value": "fas,pen-square"}, 
  {"name": "Alternate Pencil", "value": "fas,pencil-alt"}, 
  {"name": "Pencil Ruler", "value": "fas,pencil-ruler"}, 
  {"name": "Penny Arcade", "value": "fab,penny-arcade"}, 
  {"name": "People Carry", "value": "fas,people-carry"}, 
  {"name": "Percent", "value": "fas,percent"}, 
  {"name": "Percentage", "value": "fas,percentage"}, 
  {"name": "Periscope", "value": "fab,periscope"}, 
  {"name": "Person Entering Booth", "value": "fas,person-booth"}, 
  {"name": "Phabricator", "value": "fab,phabricator"}, 
  {"name": "Phoenix Framework", "value": "fab,phoenix-framework"}, 
  {"name": "Phoenix Squadron", "value": "fab,phoenix-squadron"}, 
  {"name": "Phone", "value": "fas,phone"}, 
  {"name": "Phone Slash", "value": "fas,phone-slash"}, 
  {"name": "Phone Square", "value": "fas,phone-square"}, 
  {"name": "Phone Volume", "value": "fas,phone-volume"}, 
  {"name": "PHP", "value": "fab,php"}, 
  {"name": "Pied Piper Logo", "value": "fab,pied-piper"}, 
  {"name": "Alternate Pied Piper Logo", "value": "fab,pied-piper-alt"}, 
  {"name": "Pied Piper-hat", "value": "fab,pied-piper-hat"}, 
  {"name": "Pied Piper PP Logo (Old)", "value": "fab,pied-piper-pp"}, 
  {"name": "Piggy Bank", "value": "fas,piggy-bank"}, 
  {"name": "Pills", "value": "fas,pills"}, 
  {"name": "Pinterest", "value": "fab,pinterest"}, 
  {"name": "Pinterest P", "value": "fab,pinterest-p"}, 
  {"name": "Pinterest Square", "value": "fab,pinterest-square"}, 
  {"name": "Place of Worship", "value": "fas,place-of-worship"}, 
  {"name": "plane", "value": "fas,plane"}, 
  {"name": "Plane Arrival", "value": "fas,plane-arrival"}, 
  {"name": "Plane Departure", "value": "fas,plane-departure"}, 
  {"name": "play", "value": "fas,play"}, 
  {"name": "Play Circle (solid)", "value": "fas,play-circle"}, 
  {"name": "Play Circle (regular)", "value": "far,play-circle"}, 
  {"name": "PlayStation", "value": "fab,playstation"}, 
  {"name": "Plug", "value": "fas,plug"}, 
  {"name": "plus", "value": "fas,plus"}, 
  {"name": "Plus Circle", "value": "fas,plus-circle"}, 
  {"name": "Plus Square (solid)", "value": "fas,plus-square"}, 
  {"name": "Plus Square (regular)", "value": "far,plus-square"}, 
  {"name": "Podcast", "value": "fas,podcast"}, 
  {"name": "Poll", "value": "fas,poll"}, 
  {"name": "Poll H", "value": "fas,poll-h"}, 
  {"name": "Poo", "value": "fas,poo"}, 
  {"name": "Poo Storm", "value": "fas,poo-storm"}, 
  {"name": "Poop", "value": "fas,poop"}, 
  {"name": "Portrait", "value": "fas,portrait"}, 
  {"name": "Pound Sign", "value": "fas,pound-sign"}, 
  {"name": "Power Off", "value": "fas,power-off"}, 
  {"name": "Pray", "value": "fas,pray"}, 
  {"name": "Praying Hands", "value": "fas,praying-hands"}, 
  {"name": "Prescription", "value": "fas,prescription"}, 
  {"name": "Prescription Bottle", "value": "fas,prescription-bottle"}, 
  {"name": "Alternate Prescription Bottle", "value": "fas,prescription-bottle-alt"}, 
  {"name": "print", "value": "fas,print"}, 
  {"name": "Procedures", "value": "fas,procedures"}, 
  {"name": "Product Hunt", "value": "fab,product-hunt"}, 
  {"name": "Project Diagram", "value": "fas,project-diagram"}, 
  {"name": "Pushed", "value": "fab,pushed"}, 
  {"name": "Puzzle Piece", "value": "fas,puzzle-piece"}, 
  {"name": "Python", "value": "fab,python"}, 
  {"name": "QQ", "value": "fab,qq"}, 
  {"name": "qrcode", "value": "fas,qrcode"}, 
  {"name": "Question", "value": "fas,question"}, 
  {"name": "Question Circle (solid)", "value": "fas,question-circle"}, 
  {"name": "Question Circle (regular)", "value": "far,question-circle"}, 
  {"name": "Quidditch", "value": "fas,quidditch"}, 
  {"name": "QuinScape", "value": "fab,quinscape"}, 
  {"name": "Quora", "value": "fab,quora"}, 
  {"name": "quote-left", "value": "fas,quote-left"}, 
  {"name": "quote-right", "value": "fas,quote-right"}, 
  {"name": "Quran", "value": "fas,quran"}, 
  {"name": "R Project", "value": "fab,r-project"}, 
  {"name": "Radiation", "value": "fas,radiation"}, 
  {"name": "Alternate Radiation", "value": "fas,radiation-alt"}, 
  {"name": "Rainbow", "value": "fas,rainbow"}, 
  {"name": "random", "value": "fas,random"}, 
  {"name": "Raspberry Pi", "value": "fab,raspberry-pi"}, 
  {"name": "Ravelry", "value": "fab,ravelry"}, 
  {"name": "React", "value": "fab,react"}, 
  {"name": "ReactEurope", "value": "fab,reacteurope"}, 
  {"name": "ReadMe", "value": "fab,readme"}, 
  {"name": "Rebel Alliance", "value": "fab,rebel"}, 
  {"name": "Receipt", "value": "fas,receipt"}, 
  {"name": "Recycle", "value": "fas,recycle"}, 
  {"name": "red river", "value": "fab,red-river"}, 
  {"name": "reddit Logo", "value": "fab,reddit"}, 
  {"name": "reddit Alien", "value": "fab,reddit-alien"}, 
  {"name": "reddit Square", "value": "fab,reddit-square"}, 
  {"name": "Redhat", "value": "fab,redhat"}, 
  {"name": "Redo", "value": "fas,redo"}, 
  {"name": "Alternate Redo", "value": "fas,redo-alt"}, 
  {"name": "Registered Trademark (solid)", "value": "fas,registered"}, 
  {"name": "Registered Trademark (regular)", "value": "far,registered"}, 
  {"name": "Renren", "value": "fab,renren"}, 
  {"name": "Reply", "value": "fas,reply"}, 
  {"name": "reply-all", "value": "fas,reply-all"}, 
  {"name": "replyd", "value": "fab,replyd"}, 
  {"name": "Republican", "value": "fas,republican"}, 
  {"name": "Researchgate", "value": "fab,researchgate"}, 
  {"name": "Resolving", "value": "fab,resolving"}, 
  {"name": "Restroom", "value": "fas,restroom"}, 
  {"name": "Retweet", "value": "fas,retweet"}, 
  {"name": "Rev.io", "value": "fab,rev"}, 
  {"name": "Ribbon", "value": "fas,ribbon"}, 
  {"name": "Ring", "value": "fas,ring"}, 
  {"name": "road", "value": "fas,road"}, 
  {"name": "Robot", "value": "fas,robot"}, 
  {"name": "rocket", "value": "fas,rocket"}, 
  {"name": "Rocket.Chat", "value": "fab,rocketchat"}, 
  {"name": "Rockrms", "value": "fab,rockrms"}, 
  {"name": "Route", "value": "fas,route"}, 
  {"name": "rss", "value": "fas,rss"}, 
  {"name": "RSS Square", "value": "fas,rss-square"}, 
  {"name": "Ruble Sign", "value": "fas,ruble-sign"}, 
  {"name": "Ruler", "value": "fas,ruler"}, 
  {"name": "Ruler Combined", "value": "fas,ruler-combined"}, 
  {"name": "Ruler Horizontal", "value": "fas,ruler-horizontal"}, 
  {"name": "Ruler Vertical", "value": "fas,ruler-vertical"}, 
  {"name": "Running", "value": "fas,running"}, 
  {"name": "Indian Rupee Sign", "value": "fas,rupee-sign"}, 
  {"name": "Crying Face (solid)", "value": "fas,sad-cry"}, 
  {"name": "Crying Face (regular)", "value": "far,sad-cry"}, 
  {"name": "Loudly Crying Face (solid)", "value": "fas,sad-tear"}, 
  {"name": "Loudly Crying Face (regular)", "value": "far,sad-tear"}, 
  {"name": "Safari", "value": "fab,safari"}, 
  {"name": "Sass", "value": "fab,sass"}, 
  {"name": "Satellite", "value": "fas,satellite"}, 
  {"name": "Satellite Dish", "value": "fas,satellite-dish"}, 
  {"name": "Save (solid)", "value": "fas,save"}, 
  {"name": "Save (regular)", "value": "far,save"}, 
  {"name": "SCHLIX", "value": "fab,schlix"}, 
  {"name": "School", "value": "fas,school"}, 
  {"name": "Screwdriver", "value": "fas,screwdriver"}, 
  {"name": "Scribd", "value": "fab,scribd"}, 
  {"name": "Scroll", "value": "fas,scroll"}, 
  {"name": "Sd Card", "value": "fas,sd-card"}, 
  {"name": "Search", "value": "fas,search"}, 
  {"name": "Search Dollar", "value": "fas,search-dollar"}, 
  {"name": "Search Location", "value": "fas,search-location"}, 
  {"name": "Search Minus", "value": "fas,search-minus"}, 
  {"name": "Search Plus", "value": "fas,search-plus"}, 
  {"name": "Searchengin", "value": "fab,searchengin"}, 
  {"name": "Seedling", "value": "fas,seedling"}, 
  {"name": "Sellcast", "value": "fab,sellcast"}, 
  {"name": "Sellsy", "value": "fab,sellsy"}, 
  {"name": "Server", "value": "fas,server"}, 
  {"name": "Servicestack", "value": "fab,servicestack"}, 
  {"name": "Shapes", "value": "fas,shapes"}, 
  {"name": "Share", "value": "fas,share"}, 
  {"name": "Alternate Share", "value": "fas,share-alt"}, 
  {"name": "Alternate Share Square", "value": "fas,share-alt-square"}, 
  {"name": "Share Square (solid)", "value": "fas,share-square"}, 
  {"name": "Share Square (regular)", "value": "far,share-square"}, 
  {"name": "Shekel Sign", "value": "fas,shekel-sign"}, 
  {"name": "Alternate Shield", "value": "fas,shield-alt"}, 
  {"name": "Ship", "value": "fas,ship"}, 
  {"name": "Shipping Fast", "value": "fas,shipping-fast"}, 
  {"name": "Shirts in Bulk", "value": "fab,shirtsinbulk"}, 
  {"name": "Shoe Prints", "value": "fas,shoe-prints"}, 
  {"name": "Shopping Bag", "value": "fas,shopping-bag"}, 
  {"name": "Shopping Basket", "value": "fas,shopping-basket"}, 
  {"name": "shopping-cart", "value": "fas,shopping-cart"}, 
  {"name": "Shopware", "value": "fab,shopware"}, 
  {"name": "Shower", "value": "fas,shower"}, 
  {"name": "Shuttle Van", "value": "fas,shuttle-van"}, 
  {"name": "Sign", "value": "fas,sign"}, 
  {"name": "Alternate Sign In", "value": "fas,sign-in-alt"}, 
  {"name": "Sign Language", "value": "fas,sign-language"}, 
  {"name": "Alternate Sign Out", "value": "fas,sign-out-alt"}, 
  {"name": "signal", "value": "fas,signal"}, 
  {"name": "Signature", "value": "fas,signature"}, 
  {"name": "SIM Card", "value": "fas,sim-card"}, 
  {"name": "SimplyBuilt", "value": "fab,simplybuilt"}, 
  {"name": "SISTRIX", "value": "fab,sistrix"}, 
  {"name": "Sitemap", "value": "fas,sitemap"}, 
  {"name": "Sith", "value": "fab,sith"}, 
  {"name": "Skating", "value": "fas,skating"}, 
  {"name": "Sketch", "value": "fab,sketch"}, 
  {"name": "Skiing", "value": "fas,skiing"}, 
  {"name": "Skiing Nordic", "value": "fas,skiing-nordic"}, 
  {"name": "Skull", "value": "fas,skull"}, 
  {"name": "Skull & Crossbones", "value": "fas,skull-crossbones"}, 
  {"name": "skyatlas", "value": "fab,skyatlas"}, 
  {"name": "Skype", "value": "fab,skype"}, 
  {"name": "Slack Logo", "value": "fab,slack"}, 
  {"name": "Slack Hashtag", "value": "fab,slack-hash"}, 
  {"name": "Slash", "value": "fas,slash"}, 
  {"name": "Sleigh", "value": "fas,sleigh"}, 
  {"name": "Horizontal Sliders", "value": "fas,sliders-h"}, 
  {"name": "Slideshare", "value": "fab,slideshare"}, 
  {"name": "Smiling Face (solid)", "value": "fas,smile"}, 
  {"name": "Smiling Face (regular)", "value": "far,smile"}, 
  {"name": "Beaming Face With Smiling Eyes (solid)", "value": "fas,smile-beam"}, 
  {"name": "Beaming Face With Smiling Eyes (regular)", "value": "far,smile-beam"}, 
  {"name": "Winking Face (solid)", "value": "fas,smile-wink"}, 
  {"name": "Winking Face (regular)", "value": "far,smile-wink"}, 
  {"name": "Smog", "value": "fas,smog"}, 
  {"name": "Smoking", "value": "fas,smoking"}, 
  {"name": "Smoking Ban", "value": "fas,smoking-ban"}, 
  {"name": "SMS", "value": "fas,sms"}, 
  {"name": "Snapchat", "value": "fab,snapchat"}, 
  {"name": "Snapchat Ghost", "value": "fab,snapchat-ghost"}, 
  {"name": "Snapchat Square", "value": "fab,snapchat-square"}, 
  {"name": "Snowboarding", "value": "fas,snowboarding"}, 
  {"name": "Snowflake (solid)", "value": "fas,snowflake"}, 
  {"name": "Snowflake (regular)", "value": "far,snowflake"}, 
  {"name": "Snowman", "value": "fas,snowman"}, 
  {"name": "Snowplow", "value": "fas,snowplow"}, 
  {"name": "Socks", "value": "fas,socks"}, 
  {"name": "Solar Panel", "value": "fas,solar-panel"}, 
  {"name": "Sort", "value": "fas,sort"}, 
  {"name": "Sort Alpha Down", "value": "fas,sort-alpha-down"}, 
  {"name": "Sort Alpha Up", "value": "fas,sort-alpha-up"}, 
  {"name": "Sort Amount Down", "value": "fas,sort-amount-down"}, 
  {"name": "Sort Amount Up", "value": "fas,sort-amount-up"}, 
  {"name": "Sort Down (Descending)", "value": "fas,sort-down"}, 
  {"name": "Sort Numeric Down", "value": "fas,sort-numeric-down"}, 
  {"name": "Sort Numeric Up", "value": "fas,sort-numeric-up"}, 
  {"name": "Sort Up (Ascending)", "value": "fas,sort-up"}, 
  {"name": "SoundCloud", "value": "fab,soundcloud"}, 
  {"name": "Sourcetree", "value": "fab,sourcetree"}, 
  {"name": "Spa", "value": "fas,spa"}, 
  {"name": "Space Shuttle", "value": "fas,space-shuttle"}, 
  {"name": "Speakap", "value": "fab,speakap"}, 
  {"name": "Spider", "value": "fas,spider"}, 
  {"name": "Spinner", "value": "fas,spinner"}, 
  {"name": "Splotch", "value": "fas,splotch"}, 
  {"name": "Spotify", "value": "fab,spotify"}, 
  {"name": "Spray Can", "value": "fas,spray-can"}, 
  {"name": "Square (solid)", "value": "fas,square"}, 
  {"name": "Square (regular)", "value": "far,square"}, 
  {"name": "Square Full", "value": "fas,square-full"}, 
  {"name": "Alternate Square Root", "value": "fas,square-root-alt"}, 
  {"name": "Squarespace", "value": "fab,squarespace"}, 
  {"name": "Stack Exchange", "value": "fab,stack-exchange"}, 
  {"name": "Stack Overflow", "value": "fab,stack-overflow"}, 
  {"name": "Stamp", "value": "fas,stamp"}, 
  {"name": "Star (solid)", "value": "fas,star"}, 
  {"name": "Star (regular)", "value": "far,star"}, 
  {"name": "Star and Crescent", "value": "fas,star-and-crescent"}, 
  {"name": "star-half (solid)", "value": "fas,star-half"}, 
  {"name": "star-half (regular)", "value": "far,star-half"}, 
  {"name": "Alternate Star Half", "value": "fas,star-half-alt"}, 
  {"name": "Star of David", "value": "fas,star-of-david"}, 
  {"name": "Star of Life", "value": "fas,star-of-life"}, 
  {"name": "StayLinked", "value": "fab,staylinked"}, 
  {"name": "Steam", "value": "fab,steam"}, 
  {"name": "Steam Square", "value": "fab,steam-square"}, 
  {"name": "Steam Symbol", "value": "fab,steam-symbol"}, 
  {"name": "step-backward", "value": "fas,step-backward"}, 
  {"name": "step-forward", "value": "fas,step-forward"}, 
  {"name": "Stethoscope", "value": "fas,stethoscope"}, 
  {"name": "Sticker Mule", "value": "fab,sticker-mule"}, 
  {"name": "Sticky Note (solid)", "value": "fas,sticky-note"}, 
  {"name": "Sticky Note (regular)", "value": "far,sticky-note"}, 
  {"name": "stop", "value": "fas,stop"}, 
  {"name": "Stop Circle (solid)", "value": "fas,stop-circle"}, 
  {"name": "Stop Circle (regular)", "value": "far,stop-circle"}, 
  {"name": "Stopwatch", "value": "fas,stopwatch"}, 
  {"name": "Store", "value": "fas,store"}, 
  {"name": "Alternate Store", "value": "fas,store-alt"}, 
  {"name": "Strava", "value": "fab,strava"}, 
  {"name": "Stream", "value": "fas,stream"}, 
  {"name": "Street View", "value": "fas,street-view"}, 
  {"name": "Strikethrough", "value": "fas,strikethrough"}, 
  {"name": "Stripe", "value": "fab,stripe"}, 
  {"name": "Stripe S", "value": "fab,stripe-s"}, 
  {"name": "Stroopwafel", "value": "fas,stroopwafel"}, 
  {"name": "Studio Vinari", "value": "fab,studiovinari"}, 
  {"name": "StumbleUpon Logo", "value": "fab,stumbleupon"}, 
  {"name": "StumbleUpon Circle", "value": "fab,stumbleupon-circle"}, 
  {"name": "subscript", "value": "fas,subscript"}, 
  {"name": "Subway", "value": "fas,subway"}, 
  {"name": "Suitcase", "value": "fas,suitcase"}, 
  {"name": "Suitcase Rolling", "value": "fas,suitcase-rolling"}, 
  {"name": "Sun (solid)", "value": "fas,sun"}, 
  {"name": "Sun (regular)", "value": "far,sun"}, 
  {"name": "Superpowers", "value": "fab,superpowers"}, 
  {"name": "superscript", "value": "fas,superscript"}, 
  {"name": "Supple", "value": "fab,supple"}, 
  {"name": "Hushed Face (solid)", "value": "fas,surprise"}, 
  {"name": "Hushed Face (regular)", "value": "far,surprise"}, 
  {"name": "Suse", "value": "fab,suse"}, 
  {"name": "Swatchbook", "value": "fas,swatchbook"}, 
  {"name": "Swimmer", "value": "fas,swimmer"}, 
  {"name": "Swimming Pool", "value": "fas,swimming-pool"}, 
  {"name": "Synagogue", "value": "fas,synagogue"}, 
  {"name": "Sync", "value": "fas,sync"}, 
  {"name": "Alternate Sync", "value": "fas,sync-alt"}, 
  {"name": "Syringe", "value": "fas,syringe"}, 
  {"name": "table", "value": "fas,table"}, 
  {"name": "Table Tennis", "value": "fas,table-tennis"}, 
  {"name": "tablet", "value": "fas,tablet"}, 
  {"name": "Alternate Tablet", "value": "fas,tablet-alt"}, 
  {"name": "Tablets", "value": "fas,tablets"}, 
  {"name": "Alternate Tachometer", "value": "fas,tachometer-alt"}, 
  {"name": "tag", "value": "fas,tag"}, 
  {"name": "tags", "value": "fas,tags"}, 
  {"name": "Tape", "value": "fas,tape"}, 
  {"name": "Tasks", "value": "fas,tasks"}, 
  {"name": "Taxi", "value": "fas,taxi"}, 
  {"name": "TeamSpeak", "value": "fab,teamspeak"}, 
  {"name": "Teeth", "value": "fas,teeth"}, 
  {"name": "Teeth Open", "value": "fas,teeth-open"}, 
  {"name": "Telegram", "value": "fab,telegram"}, 
  {"name": "Telegram Plane", "value": "fab,telegram-plane"}, 
  {"name": "High Temperature", "value": "fas,temperature-high"}, 
  {"name": "Low Temperature", "value": "fas,temperature-low"}, 
  {"name": "Tencent Weibo", "value": "fab,tencent-weibo"}, 
  {"name": "Tenge", "value": "fas,tenge"}, 
  {"name": "Terminal", "value": "fas,terminal"}, 
  {"name": "text-height", "value": "fas,text-height"}, 
  {"name": "text-width", "value": "fas,text-width"}, 
  {"name": "th", "value": "fas,th"}, 
  {"name": "th-large", "value": "fas,th-large"}, 
  {"name": "th-list", "value": "fas,th-list"}, 
  {"name": "The Red Yeti", "value": "fab,the-red-yeti"}, 
  {"name": "Theater Masks", "value": "fas,theater-masks"}, 
  {"name": "Themeco", "value": "fab,themeco"}, 
  {"name": "ThemeIsle", "value": "fab,themeisle"}, 
  {"name": "Thermometer", "value": "fas,thermometer"}, 
  {"name": "Thermometer Empty", "value": "fas,thermometer-empty"}, 
  {"name": "Thermometer Full", "value": "fas,thermometer-full"}, 
  {"name": "Thermometer 1/2 Full", "value": "fas,thermometer-half"}, 
  {"name": "Thermometer 1/4 Full", "value": "fas,thermometer-quarter"}, 
  {"name": "Thermometer 3/4 Full", "value": "fas,thermometer-three-quarters"}, 
  {"name": "Think Peaks", "value": "fab,think-peaks"}, 
  {"name": "thumbs-down (solid)", "value": "fas,thumbs-down"}, 
  {"name": "thumbs-down (regular)", "value": "far,thumbs-down"}, 
  {"name": "thumbs-up (solid)", "value": "fas,thumbs-up"}, 
  {"name": "thumbs-up (regular)", "value": "far,thumbs-up"}, 
  {"name": "Thumbtack", "value": "fas,thumbtack"}, 
  {"name": "Alternate Ticket", "value": "fas,ticket-alt"}, 
  {"name": "Times", "value": "fas,times"}, 
  {"name": "Times Circle (solid)", "value": "fas,times-circle"}, 
  {"name": "Times Circle (regular)", "value": "far,times-circle"}, 
  {"name": "tint", "value": "fas,tint"}, 
  {"name": "Tint Slash", "value": "fas,tint-slash"}, 
  {"name": "Tired Face (solid)", "value": "fas,tired"}, 
  {"name": "Tired Face (regular)", "value": "far,tired"}, 
  {"name": "Toggle Off", "value": "fas,toggle-off"}, 
  {"name": "Toggle On", "value": "fas,toggle-on"}, 
  {"name": "Toilet", "value": "fas,toilet"}, 
  {"name": "Toilet Paper", "value": "fas,toilet-paper"}, 
  {"name": "Toolbox", "value": "fas,toolbox"}, 
  {"name": "Tools", "value": "fas,tools"}, 
  {"name": "Tooth", "value": "fas,tooth"}, 
  {"name": "Torah", "value": "fas,torah"}, 
  {"name": "Torii Gate", "value": "fas,torii-gate"}, 
  {"name": "Tractor", "value": "fas,tractor"}, 
  {"name": "Trade Federation", "value": "fab,trade-federation"}, 
  {"name": "Trademark", "value": "fas,trademark"}, 
  {"name": "Traffic Light", "value": "fas,traffic-light"}, 
  {"name": "Train", "value": "fas,train"}, 
  {"name": "Tram", "value": "fas,tram"}, 
  {"name": "Transgender", "value": "fas,transgender"}, 
  {"name": "Alternate Transgender", "value": "fas,transgender-alt"}, 
  {"name": "Trash", "value": "fas,trash"}, 
  {"name": "Alternate Trash (solid)", "value": "fas,trash-alt"}, 
  {"name": "Alternate Trash (regular)", "value": "far,trash-alt"}, 
  {"name": "Tree", "value": "fas,tree"}, 
  {"name": "Trello", "value": "fab,trello"}, 
  {"name": "TripAdvisor", "value": "fab,tripadvisor"}, 
  {"name": "trophy", "value": "fas,trophy"}, 
  {"name": "truck", "value": "fas,truck"}, 
  {"name": "Truck Loading", "value": "fas,truck-loading"}, 
  {"name": "Truck Monster", "value": "fas,truck-monster"}, 
  {"name": "Truck Moving", "value": "fas,truck-moving"}, 
  {"name": "Truck Side", "value": "fas,truck-pickup"}, 
  {"name": "T-Shirt", "value": "fas,tshirt"}, 
  {"name": "TTY", "value": "fas,tty"}, 
  {"name": "Tumblr", "value": "fab,tumblr"}, 
  {"name": "Tumblr Square", "value": "fab,tumblr-square"}, 
  {"name": "Television", "value": "fas,tv"}, 
  {"name": "Twitch", "value": "fab,twitch"}, 
  {"name": "Twitter", "value": "fab,twitter"}, 
  {"name": "Twitter Square", "value": "fab,twitter-square"}, 
  {"name": "Typo3", "value": "fab,typo3"}, 
  {"name": "Uber", "value": "fab,uber"}, 
  {"name": "Ubuntu", "value": "fab,ubuntu"}, 
  {"name": "UIkit", "value": "fab,uikit"}, 
  {"name": "Umbrella", "value": "fas,umbrella"}, 
  {"name": "Umbrella Beach", "value": "fas,umbrella-beach"}, 
  {"name": "Underline", "value": "fas,underline"}, 
  {"name": "Undo", "value": "fas,undo"}, 
  {"name": "Alternate Undo", "value": "fas,undo-alt"}, 
  {"name": "Uniregistry", "value": "fab,uniregistry"}, 
  {"name": "Universal Access", "value": "fas,universal-access"}, 
  {"name": "University", "value": "fas,university"}, 
  {"name": "unlink", "value": "fas,unlink"}, 
  {"name": "unlock", "value": "fas,unlock"}, 
  {"name": "Alternate Unlock", "value": "fas,unlock-alt"}, 
  {"name": "Untappd", "value": "fab,untappd"}, 
  {"name": "Upload", "value": "fas,upload"}, 
  {"name": "UPS", "value": "fab,ups"}, 
  {"name": "USB", "value": "fab,usb"}, 
  {"name": "User (solid)", "value": "fas,user"}, 
  {"name": "User (regular)", "value": "far,user"}, 
  {"name": "Alternate User", "value": "fas,user-alt"}, 
  {"name": "Alternate User Slash", "value": "fas,user-alt-slash"}, 
  {"name": "User Astronaut", "value": "fas,user-astronaut"}, 
  {"name": "User Check", "value": "fas,user-check"}, 
  {"name": "User Circle (solid)", "value": "fas,user-circle"}, 
  {"name": "User Circle (regular)", "value": "far,user-circle"}, 
  {"name": "User Clock", "value": "fas,user-clock"}, 
  {"name": "User Cog", "value": "fas,user-cog"}, 
  {"name": "User Edit", "value": "fas,user-edit"}, 
  {"name": "User Friends", "value": "fas,user-friends"}, 
  {"name": "User Graduate", "value": "fas,user-graduate"}, 
  {"name": "User Injured", "value": "fas,user-injured"}, 
  {"name": "User Lock", "value": "fas,user-lock"}, 
  {"name": "user-md", "value": "fas,user-md"}, 
  {"name": "User Minus", "value": "fas,user-minus"}, 
  {"name": "User Ninja", "value": "fas,user-ninja"}, 
  {"name": "User Plus", "value": "fas,user-plus"}, 
  {"name": "User Secret", "value": "fas,user-secret"}, 
  {"name": "User Shield", "value": "fas,user-shield"}, 
  {"name": "User Slash", "value": "fas,user-slash"}, 
  {"name": "User Tag", "value": "fas,user-tag"}, 
  {"name": "User Tie", "value": "fas,user-tie"}, 
  {"name": "Remove User", "value": "fas,user-times"}, 
  {"name": "Users", "value": "fas,users"}, 
  {"name": "Users Cog", "value": "fas,users-cog"}, 
  {"name": "United States Postal Service", "value": "fab,usps"}, 
  {"name": "us-Sunnah Foundation", "value": "fab,ussunnah"}, 
  {"name": "Utensil Spoon", "value": "fas,utensil-spoon"}, 
  {"name": "Utensils", "value": "fas,utensils"}, 
  {"name": "Vaadin", "value": "fab,vaadin"}, 
  {"name": "Vector Square", "value": "fas,vector-square"}, 
  {"name": "Venus", "value": "fas,venus"}, 
  {"name": "Venus Double", "value": "fas,venus-double"}, 
  {"name": "Venus Mars", "value": "fas,venus-mars"}, 
  {"name": "Viacoin", "value": "fab,viacoin"}, 
  {"name": "Video", "value": "fab,viadeo"}, 
  {"name": "Video Square", "value": "fab,viadeo-square"}, 
  {"name": "Vial", "value": "fas,vial"}, 
  {"name": "Vials", "value": "fas,vials"}, 
  {"name": "Viber", "value": "fab,viber"}, 
  {"name": "Video", "value": "fas,video"}, 
  {"name": "Video Slash", "value": "fas,video-slash"}, 
  {"name": "Vihara", "value": "fas,vihara"}, 
  {"name": "Vimeo", "value": "fab,vimeo"}, 
  {"name": "Vimeo Square", "value": "fab,vimeo-square"}, 
  {"name": "Vimeo", "value": "fab,vimeo-v"}, 
  {"name": "Vine", "value": "fab,vine"}, 
  {"name": "VK", "value": "fab,vk"}, 
  {"name": "VNV", "value": "fab,vnv"}, 
  {"name": "Volleyball Ball", "value": "fas,volleyball-ball"}, 
  {"name": "Volume Down", "value": "fas,volume-down"}, 
  {"name": "Volume Mute", "value": "fas,volume-mute"}, 
  {"name": "Volume Off", "value": "fas,volume-off"}, 
  {"name": "Volume Up", "value": "fas,volume-up"}, 
  {"name": "Vote Yea", "value": "fas,vote-yea"}, 
  {"name": "Cardboard VR", "value": "fas,vr-cardboard"}, 
  {"name": "Vue.js", "value": "fab,vuejs"}, 
  {"name": "Walking", "value": "fas,walking"}, 
  {"name": "Wallet", "value": "fas,wallet"}, 
  {"name": "Warehouse", "value": "fas,warehouse"}, 
  {"name": "Water", "value": "fas,water"}, 
  {"name": "Weebly", "value": "fab,weebly"}, 
  {"name": "Weibo", "value": "fab,weibo"}, 
  {"name": "Weight", "value": "fas,weight"}, 
  {"name": "Hanging Weight", "value": "fas,weight-hanging"}, 
  {"name": "Weixin (WeChat)", "value": "fab,weixin"}, 
  {"name": "Whats App", "value": "fab,whatsapp"}, 
  {"name": "Whats App Square", "value": "fab,whatsapp-square"}, 
  {"name": "Wheelchair", "value": "fas,wheelchair"}, 
  {"name": "WHMCS", "value": "fab,whmcs"}, 
  {"name": "WiFi", "value": "fas,wifi"}, 
  {"name": "Wikipedia W", "value": "fab,wikipedia-w"}, 
  {"name": "Wind", "value": "fas,wind"}, 
  {"name": "Window Close (solid)", "value": "fas,window-close"}, 
  {"name": "Window Close (regular)", "value": "far,window-close"}, 
  {"name": "Window Maximize (solid)", "value": "fas,window-maximize"}, 
  {"name": "Window Maximize (regular)", "value": "far,window-maximize"}, 
  {"name": "Window Minimize (solid)", "value": "fas,window-minimize"}, 
  {"name": "Window Minimize (regular)", "value": "far,window-minimize"}, 
  {"name": "Window Restore (solid)", "value": "fas,window-restore"}, 
  {"name": "Window Restore (regular)", "value": "far,window-restore"}, 
  {"name": "Windows", "value": "fab,windows"}, 
  {"name": "Wine Bottle", "value": "fas,wine-bottle"}, 
  {"name": "Wine Glass", "value": "fas,wine-glass"}, 
  {"name": "Alternate Wine Glas", "value": "fas,wine-glass-alt"}, 
  {"name": "Wix", "value": "fab,wix"}, 
  {"name": "Wizards of the Coast", "value": "fab,wizards-of-the-coast"}, 
  {"name": "Wolf Pack Battalion", "value": "fab,wolf-pack-battalion"}, 
  {"name": "Won Sign", "value": "fas,won-sign"}, 
  {"name": "WordPress Logo", "value": "fab,wordpress"}, 
  {"name": "Wordpress Simple", "value": "fab,wordpress-simple"}, 
  {"name": "WPBeginner", "value": "fab,wpbeginner"}, 
  {"name": "WPExplorer", "value": "fab,wpexplorer"}, 
  {"name": "WPForms", "value": "fab,wpforms"}, 
  {"name": "wpressr", "value": "fab,wpressr"}, 
  {"name": "Wrench", "value": "fas,wrench"}, 
  {"name": "X-Ray", "value": "fas,x-ray"}, 
  {"name": "Xbox", "value": "fab,xbox"}, 
  {"name": "Xing", "value": "fab,xing"}, 
  {"name": "Xing Square", "value": "fab,xing-square"}, 
  {"name": "Y Combinator", "value": "fab,y-combinator"}, 
  {"name": "Yahoo Logo", "value": "fab,yahoo"}, 
  {"name": "Yandex", "value": "fab,yandex"}, 
  {"name": "Yandex International", "value": "fab,yandex-international"}, 
  {"name": "Yarn", "value": "fab,yarn"}, 
  {"name": "Yelp", "value": "fab,yelp"}, 
  {"name": "Yen Sign", "value": "fas,yen-sign"}, 
  {"name": "Yin Yang", "value": "fas,yin-yang"}, 
  {"name": "Yoast", "value": "fab,yoast"}, 
  {"name": "YouTube", "value": "fab,youtube"}, 
  {"name": "YouTube Square", "value": "fab,youtube-square"}, 
  {"name": "Zhihu", "value": "fab,zhihu"}
]};
