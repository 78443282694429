<template>
  <span class="badge badge-secondary badge-pill mr-2" style="padding: 8px 10px; margin-top: 0.2em; margin-bottom: 0.2em" :style="{ backgroundColor: getCategory.color }">
    <template v-if="getCategory">
      <i :class="getIconClass(getCategory.icon)"></i>
      {{getCategory.name}}
    </template>
    <template v-else>...</template>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      catId: {
        type: Number
      }
    },
    methods: {
      getIconClass (value) {
        let parts = value.split(",")
        return parts[0] + ' fa-fw fa-' + parts[1]
      },
    },
    computed: {
      ...mapGetters(['getCategories']),
      getCategory () {
        if (this.catId) {
          return this.getCategories.filter(cat => cat.id === this.catId)[0]
        }
        return {
          icon: null,
          name: null,
          color: null
        }
      }
    }
  };
</script>

<style>
.swatch-sm {
  display: inline-block;
  border-radius: 18px;
  width: 12px;
  height: 7px;
  margin-left: 4px;
  border: 1px solid #eee;
}
</style>
