<template>
  <ValidationObserver mode="eager" ref="observer" v-slot="{ handleSubmit }">
    <b-modal :title="modalTitle" v-model="showModal" :body-class="getModalClass" hide-header-close no-close-on-backdrop no-close-on-esc>
      <template #default>
        <b-form @submit.prevent="handleSubmit(performSave)">
          <div class="row">
            <div class="col-10">
              <ValidationProvider rules="required" name="name" v-slot="{ errors, classes }">
                <b-form-group
                  id="input-group-1"
                  label="Name:"
                  label-for="input-1"
                  :class="classes"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.name"
                    type="text"
                    placeholder="Enter name"
                    required
                  ></b-form-input>
                  <div class="error" v-if="errors">{{ errors[0] }}</div>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="col-2">
              <ValidationProvider rules="required" name="color" v-slot="{ errors, classes }">
                <b-form-group
                  id="input-group-2"
                  label="Color:"
                  label-for="input-2"
                  :class="classes"
                >
                  <v-swatches id="input-2" required v-model="form.color" :swatches="swatches" :swatchSize="32" :rowLength="9" :triggerStyle="{width: 32, height: 32}" popoverX="left" :swatchStyle="{borderRadius: '5px', marginRight: '6px', marginBottom: '2px'}" @open="handleOpenSwatches" @close="multiselectClosed"></v-swatches>
                  <div class="error" v-if="errors">{{ errors[0] }}</div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
          <ValidationProvider rules="required" name="icon" v-slot="{ errors, classes }">
            <b-form-group
              ref="iconChooser"
              id="input-group-2"
              label="Icon:"
              label-for="input-2"
              :class="classes"
            >
              <multiselect v-model="form.icon" :searchable="true" label="name" track-by="value" :multiple="false" :options="getIcons" :option-height="30" @open="autoScrollMe" open-direction="bottom" @close="multiselectClosed">
                <template slot="singleLabel" slot-scope="props"><i :class="getIconClass(props.option.value)"></i> {{ props.option.name }}</template>
                <template slot="option" slot-scope="props">
                  <i :class="getIconClass(props.option.value)"></i>
                  {{ props.option.name }}
                </template>
              </multiselect>
              <div class="error" v-if="errors">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>

        </b-form>
      </template>
      <template #modal-footer>
        <button class="btn btn-secondary mr-auto" @click="closeModal">Cancel</button>
        <button class="btn btn-primary" @click="handleSubmit(performSave)">Save</button>        
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { BModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue'
  import Multiselect from 'vue-multiselect'
  import IconOptions from '@/icons.js'
  import VSwatches from 'vue-swatches'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      BForm,
      BModal,
      BFormInput,
      Multiselect,
      VSwatches,
      BFormGroup
    },
    props: {
      mode: {
        default: 'create',
        validator: function (value) {
          return ['create', 'edit'].indexOf(value) !== -1
        }
      },
      value: {
        type: Object
      }
    },
    computed: {
      modalTitle () {
        if (this.mode === 'create') {
          return "New category"
        } else {
          return "Edit category"
        }
      },
      getIcons () {
        return IconOptions.options
      },
      getModalClass () {
        if (this.selectOpened) {
          return "big"
        } else {
          return "regular"
        }
      }
    },
    watch: {
      value (newValue) {
        this.form = newValue
        this.form.icon = this.getIcon(this.form.icon.value)
      }
    },
    methods: {
      handleOpenSwatches () {
        this.selectOpened = true
      },
      autoScrollMe() {
        console.log(this.$refs.iconChooser.$el.parentNode.parentNode.parentNode)
        this.selectOpened = true
        setTimeout(()=>{
          this.$refs.iconChooser.$el.parentNode.parentNode.parentNode.parentNode.scrollTop = 300
          this.$refs.iconChooser.$el.parentNode.parentNode.parentNode.parentNode.height = 500
        }, 500)
      },
      multiselectClosed() {
        this.selectOpened = false
      },
      getIcon(val) {
        return this.getIcons.filter(icon => icon.value === val)[0]
      },
      getIconClass (value) {
        let parts = value.split(",")
        return parts[0] + ' fa-fw fa-' + parts[1]
      },
      closeModal () {
        this.$emit('close')
      },
      performSave () {
        this.$refs.observer.validate().then((isValid) => {
          if (isValid) {
            let payload = this.prepareData()
            console.log(payload)
            this.$emit('save', payload)
            this.closeModal()
          }
        })
      },
      prepareData () {
        return {
          id: this.form.id,
          name: this.form.name,
          icon: this.form.icon.value,
          color: this.form.color          
        }
      }
    },
    mounted () {
      if (this.value) {
        this.form = this.value
        this.form.icon = this.getIcon(this.form.icon.value)
      }
    },
    data () {
      return {
        showModal: true,
        selectOpened: false,
        swatches:[ 
          '#fe8081', '#ffaa81', '#ffc050', '#ffd302', '#a5e800', '#02ef02', '#00ef8e', '#00e5d9', '#80d5fe',
          '#ff191a', '#ff661b', '#f29500', '#d8be00', '#91cc01', '#00cc00', '#00c977', '#01bdd3', '#19b2fe',
          '#a10000', '#993400', '#9a6600', '#959800', '#619901', '#008e00', '#03935f', '#00898c', '#006499',
          '#80aaff', '#8080ff', '#aa80fe', '#d580ff', '#ff81ff', '#ff80d5', '#f280a4', '#dd9f6e', '#ababab',
          '#1865ff', '#1819ff', '#6618ff', '#b31bfc', '#ff19ff', '#ff18b2', '#ff1967', '#aa5a1d', '#666666',
          '#003399', '#00009a', '#330099', '#660099', '#990099', '#980065', '#990134', '#814217', '#343434'
        ],
        form: {
          id: null,
          name: null,
          icon: null,
          color: null
        }
      }
    }
  };
</script>

<style>
  .error {
    color: red;
    font-size: 0.9em;
    margin-left: 3px;
    text-align: right;
  }
  .big {
    height: 50vh;
  }
  .regular {
    height: auto;
  }
  .multiselect__option::after {
    font-weight: 100;
  }

</style>
