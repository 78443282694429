<template>
  <div class="users-table"> 
    <table class="table table-sm table-hover" v-if="isLoading">
      <thead>
        <tr>
          <th style="text-align: left">Name</th>
          <th style="text-align: left">Icon</th>
          <th style="text-align: right">Color</th>
          <th style="text-align: right"></th>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.email" v-for="item in loadingData">
          <td align="left" class=" overflow-ellipsis"><skeleton-box /></td>
          <td align="left"><skeleton-box /></td>
          <td align="right"><skeleton-box /></td>
          <td align="right"><skeleton-box /></td>
        </tr>
      </tbody>
    </table>
    <b-table 
      :fields="fields" 
      :items="data"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      small v-else>

      <template #cell(icon)="row">
        <i :class="getIconClass(row.value)"></i>
      </template>
      <template #cell(color)="row">
        <span class="swatch" :style="getColorStyle(row.value)"></span>
      </template>
      <template #cell(actions)="row">
        <b-button-group>
          <b-button size="sm" :data-id="row.index" variant="link" title="Edit category" @click="editCallback(row.item.id)">
            <svg version="1.1" class="svg-icon blue-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
            <g>
              <polygon class="st0" points="0,16 5.4,14.7 1.3,10.6   "/>
              <rect x="3.5" y="3.5" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -1.6927 8.6718)" class="st0" width="12.2" height="5.8"/>
            </g>
            </svg>
          </b-button>
          <b-button size="sm" variant="link" @click="deleteCallback(row.item.id)" title="Delete category">
            <svg version="1.1" class="svg-icon red-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 14 16.9" style="enable-background:new 0 0 14 16.9;" xml:space="preserve">
            <g>
              <path class="st0" d="M1,14.9c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-10H1V14.9z"/>
              <polygon class="st0" points="9,1 9,0 5,0 5,1 0,1 0,3 14,3 14,1  "/>
            </g>
            </svg>
          </b-button>
        </b-button-group>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="right"
      size="sm"
      class="my-0"
      v-show="paginationNeeded"
    ></b-pagination>


  </div>
</template>
<script>
import { BTable, BPagination, BButton, BButtonGroup } from 'bootstrap-vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BPagination,
    BTable,
    SkeletonBox
  },
  props: {
    data: {type: Array},
    isLoading: {
      type: Boolean,
      default: false
    },
    editCallback: {
      type: Function
    },
    deleteCallback: {
      type: Function
    }
  },
  computed: {
    totalRows () {
      return this.data.length
    },
    paginationNeeded () {
      return this.data.length > this.perPage
    }
  },
  methods: {
    getIconClass (value) {
      let parts = value.split(",")
      return parts[0] + ' fa-fw fa-' + parts[1]
    },
    getColorStyle (value) {
      return {
        backgroundColor: value
      }
    }
  },
  data () {
    return {
      showModalAddUser: false,
      currentPage: 1,
      perPage: 100,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          thClass: "catname-col"
        },
        {
          key: "icon",
          label: "Icon",
          sortable: true,
          thClass: "colors-col"
        },
        {
          key: "color",
          label: "Color",
          sortable: false,
          thClass: ""
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thClass: "actions-col"
        }
      ],
      loadingData: [
          {"name": ".", "icon": '...', "color": '...'},
          {"name": "..", "icon": '...', "color": '...'},
          {"name": "...", "icon": '...', "color": '...'},
          {"name": "....", "icon": '...', "color": '...'},
          {"name": ".....", "icon": '...', "color": '...'}
        ]
    }
  },
  mounted () {
  }
};
</script>

<style lang="scss">
body .wrapper .stats-table .list-group .list-group-item.list-group-item--head {
  border-bottom: none;
  padding-bottom: .5em;
}

.swatch {
  display: inline-block;
  border-radius: 3px;
  border: none;
  width: 24px;
  height: 24px;
}

.stats-table {
  position: relative;
  cursor: pointer;
  .list-group {
    .list-group-item.compact {
      padding: 3px;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1,141,202, 0.05);
    opacity: 0;
    transition: all 0.5s ease;
    transform: scale(0);
    span {
      font-size: 2em;
      color: rgba(1,141,202, .9);
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
      transform: scale(1);
    }
  }
  .overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.svg-icon {
  height: 16px;
  width: 16px;
}

.blue-icon {
  fill: #a3a3a3;
  &:hover {
    fill: #008DCA;
  }  
}
.red-icon {
  fill: #a3a3a3;
  &:hover {
    fill: #D3002D;
  }
}

</style>
