var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"title":_vm.modalTitle,"body-class":_vm.getModalClass,"hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.performSave)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-1","label":"Name:","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","type":"text","placeholder":"Enter name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-2","label":"Color:","label-for":"input-2"}},[_c('v-swatches',{attrs:{"id":"input-2","required":"","swatches":_vm.swatches,"swatchSize":32,"rowLength":9,"triggerStyle":{width: 32, height: 32},"popoverX":"left","swatchStyle":{borderRadius: '5px', marginRight: '6px', marginBottom: '2px'}},on:{"open":_vm.handleOpenSwatches,"close":_vm.multiselectClosed},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"rules":"required","name":"icon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{ref:"iconChooser",class:classes,attrs:{"id":"input-group-2","label":"Icon:","label-for":"input-2"}},[_c('multiselect',{attrs:{"searchable":true,"label":"name","track-by":"value","multiple":false,"options":_vm.getIcons,"option-height":30,"open-direction":"bottom"},on:{"open":_vm.autoScrollMe,"close":_vm.multiselectClosed},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('i',{class:_vm.getIconClass(props.option.value)}),_vm._v(" "+_vm._s(props.option.name))]}},{key:"option",fn:function(props){return [_c('i',{class:_vm.getIconClass(props.option.value)}),_vm._v(" "+_vm._s(props.option.name)+" ")]}}],null,true),model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}}),(errors)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary mr-auto",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return handleSubmit(_vm.performSave)}}},[_vm._v("Save")])]},proxy:true}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }