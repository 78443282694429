var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"title":"Change password","hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.performSave)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:35","name":"password1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-2","label":"Password:","label-for":"input-2"}},[_c('b-input-group',[_c('b-form-input',{ref:"password1",attrs:{"id":"input-2","type":_vm.pass1Type,"autocomplete":"new-password","required":""},model:{value:(_vm.form.password1),callback:function ($$v) {_vm.$set(_vm.form, "password1", $$v)},expression:"form.password1"}}),_c('b-input-group-append',{staticClass:"ml-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.togglePass1Type}},[_vm._v(_vm._s(_vm.pass1Btn))])],1)],1),(errors[0])?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password1","name":"confirm password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"id":"input-group-1","label":"Confirm password:","label-for":"input-1"}},[_c('b-input-group',[_c('b-form-input',{ref:"password2",attrs:{"id":"input-1","type":_vm.pass2Type,"autocomplete":"new-password","required":""},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}}),_c('b-input-group-append',{staticClass:"ml-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.togglePass2Type}},[_vm._v(_vm._s(_vm.pass2Btn))])],1)],1),(errors[0])?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary mr-auto",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return handleSubmit(_vm.performSave)}}},[_vm._v("Save")])]},proxy:true}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }