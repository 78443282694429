<template>
  <div class="users-table"> 
    <table class="table table-sm table-hover" v-if="isLoading">
      <thead>
        <tr>
          <th style="text-align: left">Name</th>
          <th style="text-align: left">Role</th>
          <th style="text-align: left">Email</th>
          <th style="text-align: left">Categories</th>
          <th style="text-align: right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.email" v-for="item in loadingData">
          <td align="left" class=" overflow-ellipsis"><skeleton-box /></td>
          <td align="left"><skeleton-box /></td>
          <td align="left"><skeleton-box /></td>
          <td align="left"><skeleton-box /></td>
          <td align="right"><skeleton-box /></td>
        </tr>
      </tbody>
    </table>
    <b-table 
      :fields="fields" 
      :items="data"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      small v-else>

      <template #cell(role)="row">
        <span class="is-admin" v-if="row.value === 'administrator'">Admin</span>
        <span class="is-user" v-else>User</span>
      </template>
      <template #cell(assigned_thread_categories)="row">
        <thread-category v-for="catId in row.value" :key="catId" :catId="catId"></thread-category>
      </template>
      <template #cell(actions)="row">
        <b-button-group>
          <b-button size="sm" :data-id="row.index" variant="link" @click="editCallback(row.item.id)" title="Edit user">
            <svg version="1.1" class="svg-icon blue-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
            <g>
              <polygon class="st0" points="0,16 5.4,14.7 1.3,10.6   "/>
              <rect x="3.5" y="3.5" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -1.6927 8.6718)" class="st0" width="12.2" height="5.8"/>
            </g>
            </svg>

          </b-button>
          <b-button size="sm" variant="link" @click="passwordCallback(row.item.id)" title="Change password">
            <svg version="1.1" class="svg-icon blue-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 14 17" style="enable-background:new 0 0 14 17;" xml:space="preserve">
            <path class="st0" d="M12.2,6.1h-0.9V4.4C11.4,2,9.4,0,7,0C4.6,0,2.6,2,2.6,4.4v1.7H1.7C0.8,6.1,0,6.9,0,7.9v7.4c0,1,0.8,1.7,1.7,1.7
              h10.5c1,0,1.7-0.8,1.7-1.7V7.9C14,6.9,13.2,6.1,12.2,6.1z M9.7,6.1H4.3V4.4c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7V6.1z"/>
            </svg>
          </b-button>
          <b-button size="sm" variant="link" @click="deleteCallback(row.item.id)" title="Delete user">
            <svg version="1.1" class="svg-icon red-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 14 16.9" style="enable-background:new 0 0 14 16.9;" xml:space="preserve">
            <g>
              <path class="st0" d="M1,14.9c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-10H1V14.9z"/>
              <polygon class="st0" points="9,1 9,0 5,0 5,1 0,1 0,3 14,3 14,1  "/>
            </g>
            </svg>
          </b-button>
        </b-button-group>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="right"
      size="sm"
      class="my-0"
      v-show="paginationNeeded"
    ></b-pagination>


  </div>
</template>
<script>
import { BTable, BPagination, BButton, BButtonGroup } from 'bootstrap-vue'
import SkeletonBox from '@/components/SkeletonBox.vue'
import ThreadCategory from '@/components/ThreadCategory.vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BPagination,
    BTable,
    ThreadCategory,
    SkeletonBox
  },
  props: {
    data: {type: Array},
    isLoading: {
      type: Boolean,
      default: false
    },
    editCallback: {
      type: Function
    },
    deleteCallback: {
      type: Function
    },
    passwordCallback: {
      type: Function
    }
  },
  computed: {
    totalRows () {
      return this.data.length
    },
    paginationNeeded () {
      return this.data.length > this.perPage
    }
  },
  data () {
    return {
      showModalAddUser: false,
      currentPage: 1,
      perPage: 100,
      sortBy: 'nickname',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {
          key: "nickname",
          label: "Name",
          sortable: true,
          thClass: "nickname-col"
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          thClass: "role-col"
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          thClass: "email-col"
        },
        {
          key: "assigned_thread_categories",
          label: "Categories"
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thClass: "actions-col"
        }
      ],
      loadingData: [
          {"email": ".", "nickname": '...', "assigned_thread_categories": '...'},
          {"email": "..", "nickname": '...', "assigned_thread_categories": '...'},
          {"email": "...", "nickname": '...', "assigned_thread_categories": '...'},
          {"email": "....", "nickname": '...', "assigned_thread_categories": '...'},
          {"email": ".....", "nickname": '...', "assigned_thread_categories": '...'}
        ]
    }
  },
  methods: {
    getColorStyle (value) {
      return {
        backgroundColor: value
      }
    }
  },
  mounted () {
  }
};
</script>

<style lang="scss">
body .wrapper .stats-table .list-group .list-group-item.list-group-item--head {
  border-bottom: none;
  padding-bottom: .5em;
}
.swatch {
  display: inline-block;
  border-radius: 3px;
  border: none;
  width: 24px;
  height: 24px;
  position: relative;
  top: 3px;
}
.svg-icon {
  height: 16px;
  width: 16px;
}

.blue-icon {
  fill: #666;
  &:hover {
    fill: #008DCA;
  }  
}
.red-icon {
  fill: #a3a3a3;
  &:hover {
    fill: #D3002D;
  }
}
.is-admin {
  font-weight: bold;
  color: #008DCA;
}

.table.table-sm {
  tr {
    td {
      line-height: 24px;
      vertical-align: middle;
    }
  }
}

</style>
