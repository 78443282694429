<template>
  <div>
    <!--mod // USERS ////////////////////-->
    <CollapsibleArea :title="getUsersTitle">
      <template v-slot:tools>
        <button type="button" class="btn btn-primary" @click="createUserModal" :class="{'disabled': !canCreateUsers}">
          Add user
        </button>
      </template>
      <UsersList :data="getUsers" :editCallback="editUserModal" :passwordCallback="passwordModal" :deleteCallback="deleteUserConfirm"></UsersList>
    </CollapsibleArea>
    <!--/mod // USERS ////////////////////-->
    <!--mod // CATEGORIES ////////////////////-->
    <CollapsibleArea title="Categories">
      <template v-slot:tools>
        <button type="button" class="btn btn-primary" @click="createCategoryModal">
          Add category
        </button>
      </template>
      <CategoriesList :data="getCategories" :editCallback="editCategoryModal" :deleteCallback="deleteCategoryConfirm"></CategoriesList>
    </CollapsibleArea>
    <!--/mod // CATEGORIES ////////////////////-->
    <portal to="modals" v-if="showModalAddUser">
      <create-user-form @close="showModalAddUser=false" @save="performCreateUser" :has-admin="hasAdmin" :mode="modalUserMode" :value="modalUserValue" :categories="getCategories">
      </create-user-form>
    </portal>
    <portal to="modals" v-if="showModalResetPassword">
      <password-reset-form :id="passwordId" @close="showModalResetPassword=false" @manualpassword="handleManualPassword">
      </password-reset-form>
    </portal>
    <portal to="modals" v-if="showModalPassword">
      <password-user-form :id="passwordId" @close="showModalPassword=false">
      </password-user-form>
    </portal>
    <portal to="modals" v-if="showModalAddCategory">
      <create-category-form @close="showModalAddCategory=false" @save="performCreateCategory" :mode="modalCategoryMode" :value="modalCategoryValue">
      </create-category-form>
    </portal>
  </div>
</template>

<script>
  import CreateUserForm from '@/components/CreateUserForm.vue'
  import CreateCategoryForm from '@/components/CreateCategoryForm.vue'
  import PasswordUserForm from '@/components/PasswordUserForm.vue'
  import PasswordResetForm from '@/components/PasswordResetForm.vue'
  import CollapsibleArea from '@/components/CollapsibleArea.vue'
  import UsersList from '@/components/UsersList.vue'
  import CategoriesList from '@/components/CategoriesList.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      CreateUserForm,
      CreateCategoryForm,
      PasswordUserForm,
      PasswordResetForm,
      CollapsibleArea,
      UsersList,
      CategoriesList
    },
    computed: {
      ...mapGetters(['getCategories', 'getUsers', 'hasAdmin', 'getMaxUsers']),
      canCreateUsers () {
        return this.getUsers.length < this.getMaxUsers || this.getMaxUsers === -1
      },
      getUsersTitle () {
        let limit = this.getMaxUsers
        if (this.getMaxUsers === -1) {
          limit = "unlimited"
        }
        return "Users (" + this.getUsers.length + "/" + limit + ")"
      }
    },
    methods: {
      ...mapActions(['createCategory', 'deleteCategory', 'updateCategories', 'updateCategory', 'createUser', 'deleteUser', 'updateUsers', 'updateUser']),
      performCreateCategory (payload) {
        if (this.modalCategoryMode === "create") {
          return this.createCategory(payload)
        } else {
          return this.updateCategory(payload)
        }
      },
      deleteCategoryConfirm (catIndex) {
        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            let category = { ...this.getCategories.filter(cat => cat.id === catIndex)[0] }
            this.deleteCategory(category).then(()=>{
              this.$swal.fire(
                'Deleted!',
                'Your category has been deleted.',
                'success'
              )
            })
          }
        })
      },
      passwordModal (id) {
        this.passwordId = id
        this.showModalResetPassword = true
      },
      handleManualPassword () {
        this.showModalResetPassword = false
        this.showModalPassword = true
      },
      createCategoryModal () {
        this.modalCategoryMode = "create"
        this.modalCategoryValue = null
        this.showModalAddCategory= true
      },
      editCategoryModal (catIndex) {
        let category = { ...this.getCategories.filter(cat => cat.id === catIndex)[0] }
        category.icon = { value: category.icon }
        this.modalCategoryMode = "edit"
        this.modalCategoryValue = category
        this.showModalAddCategory= true
      },
      deleteUserConfirm (uIndex) {
        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            let user = { ...this.getUsers.filter(u => u.id === uIndex)[0] }
            this.deleteUser(user).then(()=>{
              this.$swal.fire(
                'User deleted!',
                'Please notice that the notes, comments and media created by this user will remain visible for the rest of users.',
                'success'
              )
            })
          }
        })
      },
      async performCreateUser (payload) {
        if (this.modalUserMode === "create") {
          if (this.canCreateUsers) {
            let subscribe_all = false
            this.$swal.fire({
              title: 'Associate to ALL old notes?',
              text: "Otherwise you can associate this user to notes manually one by one.",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#3d3',
              cancelButtonText: 'No, just create the user.',
              confirmButtonText: 'Yes, associate all!'
            }).then((result) => {
              if (result.isConfirmed) {
                subscribe_all = true
              }
            }).finally(() => {
              payload["subscribe_all"] = subscribe_all
              this.createUser(payload).then(() => {
                this.$swal.fire(
                  'Created!',
                  'Your user has been successfully created. The user will receive an email with the credentials.',
                  'success'
                )
              })
            })
          } else {
            this.$swal.fire({
              title: 'You have reached the limit of users of your current plan',
              text: "You can upgrade to a bigger plan from the 'My Account' tab in the Control panel",
              icon: 'error'
            })
          }
        } else {
          return this.updateUser(payload)
        }
      },
      createUserModal () {
        if (this.canCreateUsers) {
          this.modalUserMode = "create"
          this.modalUserValue = null
          this.showModalAddUser = true          
        } else {
          this.$swal.fire({
            title: 'You have reached the limit of users of your current plan',
            text: "You can upgrade to a bigger plan from the 'My Account' tab in the Control panel",
            icon: 'error'
          })
        }
      },
      editUserModal (uIndex) {
        let user = { ...this.getUsers.filter(u => u.id === uIndex)[0] }
        this.modalUserMode = "edit"
        this.modalUserValue = user
        this.showModalAddUser = true
      }
    },
    created () {
      // let payload = {}
      // payload['token'] = this.getToken
      // this.updateCategories(payload)
    },
    data () {
      return {
        showModalAddUser: false,
        showModalAddCategory: false,
        showModalPassword: false,
        showModalResetPassword: false,
        modalCategoryMode: "create",
        modalCategoryValue: null,
        passwordId: null,
        modalUserMode: "create",
        modalUserValue: null,
        users: [
          {
            id: 1,
            "nickname": "nick1",
            "email": "john@doe1.com",
            "assigned_thread_categories": [5, 4]
          },
          {
            id: 2,
            "nickname": "nick2",
            "email": "john@doe2.com",
            "assigned_thread_categories": [2, 3]
          },
          {
            id: 3,
            "nickname": "nick3",
            "email": "john@doe3.com",
            "assigned_thread_categories": [1, ]
          }
        ],
        categories: [
          {
            id: 1,
            name: "Category 1",
            icon: "lock",
            color: "#ff0000"
          },
          {
            id: 2,
            name: "Category 2",
            icon: "gear",
            color: "#ff00ff"
          },
          {
            id: 3,
            name: "Category 3",
            icon: "user",
            color: "#ffff00"
          }
        ]
      }
    }
  };
</script>

<style>
.btn:focus, .btn.focus {
  box-shadow: none !important;
}

  .actions-col, .colors-col {
    width: 120px;
  }
  .catname-col {
    width: 700px;
  }
  .role-col {
    width: 100px;
  }
  .nickname-col {
    width: 300px;
  }
  .email-col {
    width: 300px;
  }
  .icon-col {
    width: 30px;
  }

</style>