<template>
  <ValidationObserver mode="eager" ref="observer" v-slot="{ handleSubmit }">
    <b-modal title="Change password" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc>
      <template #default>
        <b-form @submit.prevent="handleSubmit(performSave)">
          <p class="text-muted">You are about to reset the password for this user. An email will be sent with the new credentials.
          </p>
          <p>
            Do you want to set a custom password yourself or do you want me to autogenerate a random one?
          </p>
        </b-form>
      </template>
      <template #modal-footer>
        <button class="btn btn-secondary mr-auto" @click="closeModal">Cancel</button>
        <button class="btn btn-light" @click="handleManual()">Custom password</button>
        <button class="btn btn-primary" @click="handleSubmit(performSave)">Random</button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
  import NotesAPI from '@/api/api'
  import { mapGetters } from 'vuex'
  import { ValidationObserver } from 'vee-validate'
  import { BModal } from 'bootstrap-vue'

  export default {
    components: {
      ValidationObserver,
      BModal
    },
    props: {
      id: {
        type: Number
      },
      manual: {
        type: Function
      }
    },
    computed: {
      ...mapGetters(['getToken'])
    },
    methods: {
      closeModal () {
        this.$emit('close')
      },
      handleManual () {
        this.$emit('manualpassword')
      },
      togglePass1Type () {
        if (this.pass1Type === 'text') {
          this.pass1Type = 'password'
          this.pass1Btn = 'SHOW'
        } else {
          this.pass1Type = 'text'
          this.pass1Btn = 'HIDE'          
        }
      },
      togglePass2Type () {
        if (this.pass2Type === 'text') {
          this.pass2Type = 'password'
          this.pass2Btn = 'SHOW'
        } else {
          this.pass2Type = 'text'
          this.pass2Btn = 'HIDE'          
        }
      },
      performSave () {
        this.$refs.observer.validate().then((isValid) => {
          if (isValid) {
            NotesAPI.users_reset_password(this.getToken, this.id).then(() => {
              this.$swal.fire(
                'Password was reset',
                'The password has been reset and emailed to the user successfully.',
                'success'
              )
              this.closeModal()
            })
          }
        })
      }
    },
    mounted () {
    },
    data () {
      return {
        showModal: true,
        pass1Type: 'password',
        pass1Btn: "SHOW",
        pass2Type: 'password',
        pass2Btn: "SHOW",
        form: {
          password1: null,
          password2: null,
        }
      }
    }
  };
</script>

<style>
  .error {
    color: red;
    font-size: 0.9em;
    margin-left: 3px;
  }
</style>
