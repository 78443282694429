<template>
  <ValidationObserver mode="eager" ref="observer" v-slot="{ handleSubmit }">
    <b-modal title="Change password" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc>
      <template #default>
        <b-form @submit.prevent="handleSubmit(performSave)">
          <ValidationProvider rules="required|min:6|max:35" name="password1" v-slot="{ errors, classes }">
            <b-form-group
              id="input-group-2"
              label="Password:"
              label-for="input-2"
              :class="classes"
            >
              <b-input-group>
                <b-form-input id="input-2"
                            v-model="form.password1"
                            :type="pass1Type"
                            ref="password1"
                            autocomplete="new-password"
                            required></b-form-input>
                <b-input-group-append class="ml-2">
                  <b-button @click="togglePass1Type" variant="light">{{pass1Btn}}</b-button>
                </b-input-group-append>
              </b-input-group>

              <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider rules="required|confirmed:password1" name="confirm password" v-slot="{ errors, classes }">
            <b-form-group
              id="input-group-1"
              label="Confirm password:"
              label-for="input-1"
              :class="classes"
            >
              <b-input-group>
                <b-form-input id="input-1"
                            v-model="form.password2"
                            :type="pass2Type"
                            ref="password2"
                            autocomplete="new-password"
                            required></b-form-input>
                <b-input-group-append class="ml-2">
                  <b-button @click="togglePass2Type" variant="light">{{pass2Btn}}</b-button>
                </b-input-group-append>
              </b-input-group>
              <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        </b-form>
      </template>
      <template #modal-footer>
        <button class="btn btn-secondary mr-auto" @click="closeModal">Cancel</button>
        <button class="btn btn-primary" @click="handleSubmit(performSave)">Save</button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
  import NotesAPI from '@/api/api'
  import { mapGetters } from 'vuex'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { BModal, BButton, BInputGroup, BInputGroupAppend, BForm, BFormInput, BFormGroup } from 'bootstrap-vue'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      BForm,
      BModal,
      BButton,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      BFormGroup
    },
    props: {
      id: {
        type: Number
      }
    },
    computed: {
      ...mapGetters(['getToken'])
    },
    methods: {
      closeModal () {
        this.$emit('close')
      },
      togglePass1Type () {
        if (this.pass1Type === 'text') {
          this.pass1Type = 'password'
          this.pass1Btn = 'SHOW'
        } else {
          this.pass1Type = 'text'
          this.pass1Btn = 'HIDE'          
        }
      },
      togglePass2Type () {
        if (this.pass2Type === 'text') {
          this.pass2Type = 'password'
          this.pass2Btn = 'SHOW'
        } else {
          this.pass2Type = 'text'
          this.pass2Btn = 'HIDE'          
        }
      },
      performSave () {
        this.$refs.observer.validate().then((isValid) => {
          if (isValid) {
            NotesAPI.users_set_password(this.getToken, this.id, this.form.password1).then(() => {
              this.$swal.fire(
                'New password is set',
                'User password has been changed successfully.',
                'success'
              )
              this.closeModal()
            })
          }
        })
      }
    },
    mounted () {
    },
    data () {
      return {
        showModal: true,
        pass1Type: 'password',
        pass1Btn: "SHOW",
        pass2Type: 'password',
        pass2Btn: "SHOW",
        form: {
          password1: null,
          password2: null,
        }
      }
    }
  };
</script>

<style>
  .error {
    color: red;
    font-size: 0.9em;
    margin-left: 3px;
  }
</style>
