<template>
  <ValidationObserver mode="eager" ref="observer" v-slot="{ handleSubmit }">
    <b-modal :title="modalTitle" :body-class="getModalClass" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc>
      <template #default>
        <b-form @submit.prevent="handleSubmit(performSave)">
          <ValidationProvider rules="required" name="name" v-slot="{ errors, classes }">
            <b-form-group
              id="input-group-2"
              label="Name:"
              label-for="input-2"
              :class="classes"
            >
              <b-form-input
                id="input-2"
                v-model="form.nickname"
                type="text"
                placeholder="Enter name"
                required
              ></b-form-input>
              <div class="error" v-if="errors">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider rules="required" name="role" v-slot="{ errors, classes }">
            
            <b-button id="role-help-popover" class="float-right" variant="light" size="sm" tabindex="0">
              <i class="fas fa-question"></i>
            </b-button>
            <b-popover target="role-help-popover" title="Role" triggers="hover">
              <strong>Users</strong> can only contribute to notes which they have created or to which their profiles have been assigned.<br>
              <strong>Administrators</strong> can access all notes and have permission to delete them or mark them as resolved. We recommend to assign at least one administrator.<br>
              <br>
              You can change any user's role at any time.
            </b-popover>

            <b-form-group
              id="input-group-1"
              label="Role:"
              label-for="input-role"
              :class="classes"
            >
              <multiselect :value="form.role" v-model="form.role" id="input-role" label="text" track-by="value" :options="roleOptions" size="sm" :multiple="false" :searchable="false"></multiselect>
              <div class="error" v-if="errors">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider rules="required|email" name="email" v-slot="{ errors, classes }">
            <b-form-group
              id="input-group-4"
              label="Email address:"
              label-for="input-1"
              :class="classes"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Enter email"
                required
              ></b-form-input>
              <div class="error" v-if="errors">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider rules="" name="categories" v-slot="{ errors, classes }">
            <b-form-group
              id="input-group-3"
              ref="iconChooser"
              label="Categories:"
              label-for="input-3"
              :class="classes"
            >
              <multiselect v-model="form.assigned_thread_categories" :searchable="true" :close-on-select="false" :clear-on-select="false" label="name" track-by="id" :taggable="true" :multiple="true" :options="categories" @tag="addTag" @open="autoScrollMe" open-direction="bottom" @close="multiselectClosed">

                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag" :style="{backgroundColor: props.option.color}"><span class="tag__title">{{ props.option.name }}</span> <i @mousedown.prevent="props.remove(props.option)" class="multiselect__tag-icon"></i></span>
                </template>

              </multiselect>
              <div class="error" v-if="errors">{{ errors[0] }}</div>
            </b-form-group>
          </ValidationProvider>
        </b-form>
        <div v-if="mode === 'create'" class="create-user-message"><small><em>You are about to create a new user. The credentials will be sent by email to the new user.</em></small></div>
      </template>
      <template #modal-footer>
        <button class="btn btn-outline-dark" @click="closeModal">Cancel</button>
        <button class="btn btn-primary" @click="handleSubmit(performSave)">
          <span v-if="mode === 'create'">Create</span>
          <span v-else>Save</span>
        </button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { BModal, BForm, BFormInput, BFormGroup, BButton, BPopover } from 'bootstrap-vue'
  import Multiselect from 'vue-multiselect'
  import { mapActions, mapGetters } from 'vuex'
  // import VSwatches from 'vue-swatches'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      BForm,
      BModal,
      BFormInput,
      BFormGroup,
      // BFormSelect,
      // VSwatches,
      Multiselect,
      BButton,
      BPopover
    },
    props: {
      mode: {
        default: 'create',
        validator: function (value) {
          return ['create', 'edit'].indexOf(value) !== -1
        }
      },
      value: {
        type: Object
      },
      categories: {
        type: Array,
        default () {
          return []
        }
      },
      hasAdmin: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters(['getCategories']),
      modalTitle () {
        if (this.mode === 'create') {
          return "New user"
        } else {
          return "Edit user"
        }
      },
      getModalClass () {
        if (this.selectOpened) {
          return "big"
        } else {
          return "regular"
        }
      }
    },
    watch: {
      value (newValue) {
        this.form = newValue
        if (newValue) {
          this.original_categories = newValue.assigned_thread_categories
          this.form.assigned_thread_categories = this.getCategories.filter(cat => newValue.assigned_thread_categories.includes(cat.id))
        } else {
          this.form.assigned_thread_categories = this.getCategories.slice(0, 1)
        }
      }
    },
    methods: {
      ...mapActions(['createCategory', ]),
      // handleOpenSwatches () {
      //   this.selectOpened = true
      // },
      autoScrollMe() {
        console.log(this.$refs.iconChooser.$el.parentNode.parentNode.parentNode)
        this.selectOpened = true
        setTimeout(()=>{
          this.$refs.iconChooser.$el.parentNode.parentNode.parentNode.scrollTop = 300
          this.$refs.iconChooser.$el.parentNode.parentNode.parentNode.height = 800

        }, 500)
      },
      multiselectClosed() {
        this.selectOpened = false
      },
      closeModal () {
        this.$emit('close')
      },
      performSave () {
        this.$refs.observer.validate().then((isValid) => {
          if (isValid) {
            let payload = this.prepareData()
            console.log(payload)
            this.$emit('save', payload)
            this.closeModal()
          }
        })
      },
      prepareData () {
        let cats = this.form.assigned_thread_categories
        let ids = []
        cats.forEach(item => ids.push(item.id))
        return {
          id: this.form.id,
          nickname: this.form.nickname,
          email: this.form.email,
          role: this.form.role.value,
          color: this.swatches[Math.floor(Math.random()*this.swatches.length)],
          assigned_thread_categories: ids
        }
      },
      async addTag (newTag) {
        const tag = {
          name: newTag,
          color: "#E84B3C",
          icon: "fas,map-pin"
        }
        let newCat = await this.createCategory(tag)
        this.$emit('createCategory', newCat)
        this.form.assigned_thread_categories.push(newCat)
      }
    },
    mounted () {
      if (this.value) {
        this.form = this.value
        this.form.role = this.roleOptions.filter(role => this.form.role == role.value)[0]
        this.form.assigned_thread_categories = this.getCategories.filter(cat => this.value.assigned_thread_categories.includes(cat.id))
      } else {
        this.form.assigned_thread_categories = this.getCategories.slice(0, 1)
        if (this.hasAdmin) {
          this.form.role = this.roleOptions.filter((role) => role.value == "default")[0]
        } else {
          this.form.role = this.roleOptions.filter((role) => role.value == "administrator")[0]          
        }
      }
    },
    data () {
      return {
        showModal: true,
        selectOpened: false,
        original_categories: [],
        roleOptions: [
          {"value": "default", "text": "User"},
          {"value": "administrator", "text": "Admin"},
        ],
        swatches:[ 
        '#c91c2d', '#c92d0c', '#c95000', '#c98000', '#c99f00', '#c9b100', '#9da300', '#99c900',
        '#7bc900', '#56c900', '#00c900', '#00c940', '#61c9b1', '#00c998', '#00b8c9', '#007bc9',
        '#0b52ff', '#384aff', '#603cff', '#7c34ff', '#9125ff', '#c019ff', '#c930c2', '#c954c5',
        '#6374c9', '#c95251', '#bd8484', '#90683a', '#6b6518', '#6e6e6e', '#7d7a57', '#7fc973'
        ],
        form: {
          id: null,
          nickname: null,
          role: "default",
          email: null,
          color: null,
          assigned_thread_categories: []
        }
      }
    }
  };
</script>

<style>
  .error {
    color: red;
    font-size: 0.9em;
    margin-left: 3px;
    text-align: right;
  }
  .big {
    height: 50vh;
  }
  .regular {
    height: auto;
    padding-top: 0px;
  }
  .badge-secondary {
    background-color: #999;
  }
  .multiselect__option::after {
    font-weight: 100;
  }
  .multiselect__tag {
    border-radius: 12px;
  }
  .multiselect__tag-icon::after {
    color: white;
  }
  .multiselect__tag .multiselect__tag-icon:focus, .multiselect__tag .multiselect__tag-icon:hover {
    color: white;
    background-color: rgba(0,0,0,0.6);
  }
  .create-user-message {
    line-height: 1em;
  }
  .multiselect__content-wrapper::-webkit-scrollbar-thumb {
    background: #008dca77;
  }
</style>
